import React from 'react';
import { Database } from './services/Database';
import { Navigate, NavLink } from 'react-router-dom';
import { Account } from './services/Account';

let driverNamesStr = `
Beanpole
Dally
Dilly
Divern
George Square
King Earl
MTV
O. C. Daniels
Ooze
Optimus
Raagman
Sammy Snake
Scapegrace
Scraptor
Spate
Squatorn
Steven
Tarsir
Viva
Vorder
Zyca
`

let driverStatsStr = `
3	2	5	1	2	4
1	5	3	3	3	4
4	2	1	3	4	2
3	2	2	5	4	2
5	5	1	2	2	3
4	1	4	3	3	3
2	3	3	4	4	3
4	2	2	3	3	4
4	1	3	4	2	2
1	3	5	2	2	3
4	2	4	2	2	2
1	5	5	3	2	4
4	3	2	1	4	4
2	1	3	5	5	2
3	4	2	4	5	1
2	5	3	1	1	5
3	3	3	3	3	3
4	3	1	2	4	4
2	4	2	4	4	2
4	3	4	2	1	3
5	1	2	3	1	4
`

interface DriversPageState {
  driver: string;
  passenger: string;
  scale: number;
  offstat: number;
}

class DriversPage extends React.Component<{}, DriversPageState> {
  constructor(props:{}) {
    super(props);
    this.state = {
      passenger: '',
      driver: '',
      scale: 0.75,
      offstat: 0.5,
    }
  }

  render() {
    if(!Account.isDeveloperLoggedIn())
      return <Navigate to="/" replace />

    let driverNames = driverNamesStr.split('\n');
    driverNames.splice(0, 1);
    driverNames.splice(driverNames.length-1, 1);

    let driverStats = driverStatsStr.split('\n');
    driverStats.splice(0, 1);
    driverStats.splice(driverStats.length-1, 1);

    let drivers = [];
    for(let i = 0; i < driverNames.length; i++) {
      let stats = driverStats[i].split('\t');
      let driver = Database.getDriverByName(driverNames[i]);
      drivers.push({
        id: driver.id,
        name: driverNames[i],
        talent: stats[0],
        morale: stats[1],
        wisdom: stats[2],
        aggression: stats[3],
        combat: stats[4],
        boost: stats[5]
      })
    }

    let statNames = ['Talent', 'Aggression', 'Boost', 'Morale', 'Wisdom', 'Combat'];
    let statIds = ['talent', 'aggression', 'boost', 'morale', 'wisdom', 'combat'];
    let driverFactor = [1.0, 1.0, 1.0, this.state.offstat, this.state.offstat, this.state.offstat];
    let passengerFactor = [this.state.offstat, this.state.offstat, this.state.offstat, 1.0, 1.0, 1.0];

    let driver:any = drivers.find(d => d.id == this.state.driver);
    let passenger:any = drivers.find(d => d.id == this.state.passenger);

    if(!driver) driver = drivers[0];
    if(!passenger) passenger = drivers[0];

    let rows = [];
    for(let i = 0; i < statNames.length; i++) {
      let squad = driver[statIds[i]] * driverFactor[i];
      squad += passenger[statIds[i]] * passengerFactor[i];
      squad /= this.state.scale;
      rows.push(
        <div key={i} className="flex-row">
          <div style={{width: '100px'}}>{statNames[i]}</div>
          <div style={{width: '150px', textAlign: 'center'}}>{driver[statIds[i]]}</div>
          <div style={{width: '150px', textAlign: 'center'}}>{passenger[statIds[i]]}</div>
          <div style={{width: '100px', textAlign: 'center'}}>{squad.toFixed(1)}</div>
          {/* <div style={{width: '100px', textAlign: 'center'}}>{(squad/1.5).toFixed(1)}&nbsp;&nbsp;&nbsp;({squad.toFixed(1)}) </div> */}
        </div>
      )
    }

    let driverOptions = [];
    for(let i = 0; i < drivers.length; i++)
      driverOptions.push(<option key={i} value={drivers[i].id}>{drivers[i].name}</option>)

    return (
      <div className="page">
        <div className="flex-column">
          <div className="flex-row">
            <div style={{width: '100px'}}></div>
            <div style={{width: '150px'}}>
              <select 
                value={this.state.driver} 
                style={{width: '100%'}}
                onChange={(e:any)=>this.setState({driver: e.currentTarget.value})}
              >
                {driverOptions}
              </select>
            </div>
            <div style={{width: '150px'}}>
              <select 
                value={this.state.passenger} 
                style={{width: '100%'}}
                onChange={(e:any)=>this.setState({passenger: e.currentTarget.value})}
              >
                {driverOptions}
              </select>
            </div>
            <div style={{width: '100px', textAlign: 'center'}}>
              SQUAD
            </div>
          </div>
          {rows}
          <br/>
          <div>Squad Scale:&nbsp;&nbsp;<input value={this.state.scale} onChange={(e:any)=>this.setState({scale: e.currentTarget.value})}/></div>
          <div>Off-Stat Scale:&nbsp;&nbsp;<input value={this.state.offstat} onChange={(e:any)=>this.setState({offstat: e.currentTarget.value})}/></div>

        </div>
      </div>
    )

    // let divs = [];
    // for(let i = 0; i < Database.drivers.length; i++) {
    //   let driver = Database.drivers[i];
    //   let src = `/images/drivers/${driver.id}.png`;
    //   divs.push(
    //     <div key={i}>
    //       <NavLink to={'/driver/' + driver.id}>
    //         <img style={{width: '100px'}} src={src} />
    //       </NavLink>
    //     </div>
    //   )
    // }
    
    // return (
    //   <div className="page">
    //     <div className="flex-row" style={{flexWrap: 'wrap'}}>
    //       {divs}
    //     </div>
    //   </div>
    // )
  }
}

export default DriversPage;
