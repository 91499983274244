import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Auth0PageProps {
  onUpdate?: () => void;
}

function Auth0Page({ onUpdate }: Auth0PageProps) {
  const navigate = useNavigate();
  const [message, setMessage] = useState('Completing login, please wait...');

  useEffect(() => {
    console.log("Auth0Page: Processing login");
    
    const completeLogin = async () => {
      try {
        // Call onUpdate if provided
        if (onUpdate) {
          await onUpdate();
        }
        
        // Get the return URL from sessionStorage, or default to '/'
        const returnTo = sessionStorage.getItem('loginReturnUrl') || '/';
        console.log(`Auth0Page: Will redirect to ${returnTo}`);
        
        // Clear the stored return URL before redirecting
        sessionStorage.removeItem('loginReturnUrl');
        
        // Update message and redirect
        setMessage('Login completed. Redirecting...');
        navigate(returnTo, { replace: true });
      } catch (error) {
        console.error('Auth0Page: Error during login completion:', error);
        setMessage('Login failed. Redirecting to home...');
        navigate('/', { replace: true });
      }
    };

    completeLogin();
  }, [navigate, onUpdate]);

  return (
    <div className="page">
      <div className="dialog-cover">
        <div className="dialog-panel" style={{maxWidth: '250px', padding: '20px'}}>
          {message}
        </div>
      </div>
    </div>
  );
}

export default Auth0Page;