import React from 'react';
import { Navigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Twitch } from './services/Twitch';
import { Account } from './services/Account';
import { BsTwitch, BsGoogle, BsFacebook, BsEnvelope } from 'react-icons/bs';
import { Network } from './services/Network';

const hosts = [
  'NovaRally',
  'SammySnake7', 
  'SuperDopeTV', 
  'TurisStation',
  'CryptoJack21',
  'NFTGamerTV',
  'supremecourt15'
];

const portraits = [
  '/images/nova-rally-youtube-channel-image.jpg',
  'https://static-cdn.jtvnw.net/jtv_user_pictures/215e610f-040f-4034-baea-46ad2df73eef-profile_image-70x70.png',
  'https://static-cdn.jtvnw.net/jtv_user_pictures/4212062a-2cc7-4f39-916c-44bcb20cf706-profile_image-70x70.jpeg',
  'https://static-cdn.jtvnw.net/jtv_user_pictures/2c42484f-b646-4f53-a700-1f18a9517f0d-profile_image-70x70.png',
  'https://static-cdn.jtvnw.net/jtv_user_pictures/25483872-e047-4acc-a7a9-2eee9f489efc-profile_image-70x70.png',
  'https://static-cdn.jtvnw.net/jtv_user_pictures/055a7a6c-7655-4a26-8590-342f050e564e-profile_image-70x70.png',
  'https://static-cdn.jtvnw.net/jtv_user_pictures/25483872-e047-4acc-a7a9-2eee9f489efc-profile_image-70x70.png',
  'https://static-cdn.jtvnw.net/jtv_user_pictures/88b0333e-5b0b-47fe-a525-1ecb11052c89-profile_image-70x70.png'
];

interface PlayPageState {
  id: string;
  online: boolean[];
  showLoginDialog: boolean;
}

interface HostStatusEvent extends CustomEvent {
    detail: {
        host: string;
        isOnline: boolean;
    };
}

class PlayPage extends React.Component<{}, PlayPageState> {
  protected updateTimer:any;

  constructor(props:{}) {
    super(props);
    this.state = {
      id: '',
      online: [],
      showLoginDialog: false
    }
    this.updateTimer = null;
  }

  async componentDidMount(): Promise<void> {
    console.log('Component mounting');
    await Network.ensureConnection();
    window.addEventListener('host-status-update', this.onHostStatusUpdate as EventListener);
    this.initializeComponent();
  }

  componentWillUnmount(): void {
    this.clearUpdateTimer();
  }

  initializeComponent(): void {
    console.log('Initializing component, isLoggedIn:', Account.isLoggedIn());
    if(Account.isLoggedIn()) {
      this.getStreams();
      this.setUpdateTimer();
    }
  }

  setUpdateTimer(): void {
    this.clearUpdateTimer();
    this.updateTimer = setInterval(() => {
      this.getStreams();
    }, 30000);
  }

  clearUpdateTimer(): void {
    if (this.updateTimer) {
      clearInterval(this.updateTimer);
      this.updateTimer = null;
    }
  }

  async getStreams(): Promise<void> {
    console.log('Getting streams...');
    let online = new Array(hosts.length).fill(false);
    
    await Network.send({
        id: 'check-host-status'
    });

    console.log('Initialized online status array:', online);
    this.setState({ online });
  }

  onHostStatusUpdate = (event: HostStatusEvent) => {
    console.log('PlayPage received host status update:', event.detail);
    const { host, isOnline } = event.detail;
    const index = hosts.findIndex(h => h.toLowerCase() === host.toLowerCase());
    console.log('Found host index:', index, 'for host:', host);
    console.log('Current hosts array:', hosts);
    
    if (index !== -1) {
        console.log('Updating online status for index:', index);
        const online = [...this.state.online];
        console.log('Current online array:', online);
        online[index] = isOnline;
        console.log('Updated online array:', online);
        this.setState({ online });
    } else {
        console.log('Host not found in hosts array');
    }
  }

  onLoginSuccess = () => {
    this.setState({ showLoginDialog: false });
    this.initializeComponent();
  }

  renderLoginDialog() {
    return (
      <div className="dialog-cover">
        <div className="dialog-panel" style={{width: '250px'}}>
          <div>
            <img style={{height: '36px'}} src="/images/logo.webp"/>
          </div>
          <div className="flex-column" style={{marginTop: '10px'}}>
            <button onClick={() => this.onLoginWithTwitch()}><BsTwitch style={{transform: 'translateY(5px)'}} />&nbsp;&nbsp;Login with Twitch</button>
            <button disabled={true}><BsGoogle style={{transform: 'translateY(3px)'}} />&nbsp;&nbsp;Login with Google</button>
            <button disabled={true}><BsFacebook style={{transform: 'translateY(3px)'}} />&nbsp;&nbsp;Login with Facebook</button>
            <button disabled={true}><BsEnvelope style={{transform: 'translateY(4px)'}} />&nbsp;&nbsp;Login with Email</button>
            <button style={{background: '#213e70'}} onClick={() => this.setState({showLoginDialog: false})}>Close</button>
          </div>
        </div>
      </div>
    )
  }

  onLoginWithTwitch() {
    // Store the current URL before redirecting
    sessionStorage.setItem('loginReturnUrl', window.location.pathname);
    Account.loginWithTwitch();
  }

  renderLoginMessage() {
    return (
      <div className="panel" style={{textAlign: 'center', padding: '20px', marginBottom: '20px'}}>
        <h3 style={{color: 'white'}}>Welcome to Nova Rally!</h3>
        <p>To join the excitement and play Nova Rally, please log in.</p>
        <button 
          onClick={() => this.setState({showLoginDialog: true})}
          style={{marginTop: '10px', padding: '10px 20px', fontSize: '1.1em'}}
        >
          Login to Play
        </button>
      </div>
    )
  }

  render() {
    let divs = [];
    for(let i = 0; i < hosts.length; i++) {
      let url = `/host/${hosts[i].toLowerCase()}`;
      divs.push(
        <NavLink key={i} className="panel" style={{padding: '8px', cursor: 'pointer', textDecoration: 'none'}} to={url}>
          <div className="flex-row" style={{columnGap: '15px'}}>
            <img src={portraits[i]} style={{borderRadius: '50%', width: '50px'}} />
            <div>
              <div>{hosts[i]}</div>
              {this.state.online[i] ?
                <div style={{color: 'green'}}>LIVE</div> :
                <div style={{color: 'grey'}}>Offline</div>
              }
            </div>
          </div>
        </NavLink>
      );
    }
    return (
      <div className="page">
        <div className="flex-column" style={{maxWidth: '400px', margin: 'auto'}}>
          <div style={{color: 'white'}}>NovaRally is a game played on Twitch streams.</div>
          <div style={{color: 'white', marginBottom: '10px'}}>Select from the following list of Twitch streamers that host NovaRally games!</div>
          {divs}
          {/* {!Account.isLoggedIn() && this.renderLoginMessage()}
          {Account.isLoggedIn() ? divs : (
            <div className="panel" style={{textAlign: 'center', padding: '20px'}}>
              <p>Log in to see available streams and join the game!</p>
            </div>
          )} */}
        </div>
        {this.state.showLoginDialog && this.renderLoginDialog()}
      </div>
    )
  }
}

export default PlayPage;