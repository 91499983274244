import React from 'react';
import { Database } from './services/Database';
import { Navigate } from 'react-router-dom';
import { Races } from './services/Races';

interface RacePageState {
  id: string;
  loading: boolean;
  log: any;
  stats: any;
  race: any;
  navigate: string;
  tab: number;
}

class RacePage extends React.Component<{}, RacePageState> {
  constructor(props:{}) {
    super(props);
    this.state = {
      id: '',
      loading: false,
      log: null,
      stats: null,
      race: null,
      navigate: '',
      tab: 0
    }
  }

  componentDidMount(): void {
    let parts = window.location.pathname.split('/');
    if(parts.length == 3) 
      this.loadRace(parts[2]);
  }

  async loadRace(id:string) {
    this.setState({loading: true});
    let response = await Races.getRace(id);
    if(response.success)
      this.setState({race: response.body.race});
    this.setState({id, loading: false});
  }

  onStats() {
    this.setState({tab: 1});

    if(!this.state.stats) {
      this.setState({loading: true});
      setTimeout(async () => {
        let response = await Races.getRaceStats(this.state.race.id);
        if(response.success)
          this.setState({stats: response.body.stats});
        this.setState({loading: false});
      }, 250);
    }
  }

  onLog() {
    this.setState({tab: 2});

    if(!this.state.log) {
      this.setState({loading: true});
      setTimeout(async () => {
        let response = await Races.getRaceEvents(this.state.race.id);
        if(response.success)
          this.setState({log: response.body.events});
        this.setState({loading: false});
      }, 250);
    }
  }

  getHighestStat(arr:number[]) {
    let ret:any = null;
    for(let i = 0; i < arr.length; i++)
      if(!ret || arr[i] > ret.value)
        ret = {idx: i, value: arr[i]};
    return ret;
  }

  renderLeaderboard() {
    let divs = [];

    if(this.state.race) {
      let key = 0;
      let race = this.state.race;
      console.log(race);

      let leaderboard = [];
      for(let i = 0; i < race.users.length; i++) {
        leaderboard.push({
          place: race.finish[i],
          user: race.users[i],
          car: race.cars[i],
          driver: race.drivers[i],
          passenger: race.passengers[i],
          time: race.times[i]
        })
      }

      leaderboard.sort((a, b)=>{
        if(a.place == 0 && b.place != 0)
          return 1;
        else if(a.place != 0 && b.place == 0)
          return -1;

        if(a.place > b.place)
          return 1;
        else if(a.place < b.place)
          return -1;

        return 0;
      });
      
      for(let i = 0; i < leaderboard.length; i++) {  
        let entry = leaderboard[i];
        let car = Database.getCarByServerId(entry.car);
        let driver = Database.getDriverByServerId(entry.driver);
        let passenger = Database.getDriverByServerId(entry.passenger);
        let time = (entry.time == 0) ? 'DNF' : (entry.time/1000).toFixed(2) + 's';

        divs.push(
          <div key={key++} className="flex-row" style={{justifyContent: 'space-between',border: '1px solid #ffffff40', borderRadius: '10px'}}>
            <div className="flex-column" style={{rowGap: '3px', paddingLeft: '10px'}}>
              <div>{entry.user}</div>
              <div style={{fontSize: '1.0em', color: 'gray'}}>{time}</div>
            </div>
            <div className="flex-row" style={{padding: '5px'}}>
              <img 
                style={{height: '60px', borderRadius: '6px', cursor: 'pointer'}} 
                src={`/images/cars/${car.id}.png`} 
                title={car.name} 
                onClick={()=>this.setState({navigate: '/car/' + car.id})}
              />
              <img 
                style={{height: '60px', borderRadius: '6px', cursor: 'pointer'}} 
                src={`/images/drivers/${driver.id}.png`} 
                title={driver.name} 
                onClick={()=>this.setState({navigate: '/driver/' + driver.id})}
              />
              <img 
                style={{height: '60px', borderRadius: '6px', cursor: 'pointer'}} 
                src={`/images/drivers/${passenger.id}.png`} 
                title={passenger.name} 
                onClick={()=>this.setState({navigate: '/driver/' + passenger.id})}
              />
            </div>
          </div>
        )
      }
    }

    if(this.state.loading)
      divs = [<div key={0}>Loading...</div>]

    return (
      <div className="flex-column">
        {divs}
      </div>
    )
  }

  renderNoRaceFound() {
    if(this.state.id != '' && !this.state.race)
      return <div>Race not found!</div>
    else
      return null;
  }

  renderRaceHeader() {
    if(!this.state.race)
      return null;

    let date = new Date();

    return (
      <div className="flex-row" style={{justifyContent: 'space-between', backgroundColor: '#ffffff20', padding: '10px', borderRadius: '10px'}}>
        <div className="flex-column" style={{rowGap: '0px'}}>
          <div>Canyon Chaos</div>
          <div style={{color: 'gray', fontSize: '0.9em'}}>Hosted by {this.state.race.host}</div>
        </div>
        <div className="flex-column" style={{textAlign: 'right', rowGap: '0px'}}>
          <div>{date.toLocaleDateString()}</div>
          <div style={{color: 'gray', fontSize: '0.9em'}}>{date.toLocaleTimeString()}</div>
        </div>
      </div>
    )
  }

  renderTabs() {
    if(!this.state.race)
      return null;

    let selectedStyle = {
      // textDecoration: 'underline',
      color: 'white',
      backgroundColor: '#ffffff40',
      padding: '5px 10px 5px 10px',
      borderRadius: '6px',
      cursor: 'pointer'
    }

    let normalStyle = {
      // textDecoration: 'underline',
      color: 'gray',
      padding: '5px 10px 5px 10px',
      cursor: 'pointer'
    }

    let leaderBoardStyle = (this.state.tab == 0) ? selectedStyle : normalStyle;
    let statsStyle = (this.state.tab == 1) ? selectedStyle : normalStyle;
    let eventsStyle = (this.state.tab == 2) ? selectedStyle : normalStyle;

    return (
      <div className="flex-row" style={{columnGap: '10px', backgroundColor: '#ffffff20', padding: '5px', borderRadius: '10px'}}>
        <div style={leaderBoardStyle} onClick={()=>this.setState({tab: 0})}>Results</div>
        <div style={statsStyle} onClick={()=>this.onStats()}>Stats</div>
        <div style={eventsStyle} onClick={()=>this.onLog()}>Log</div>
      </div>
    )
  }

  renderStat(label:string, stat:any) {
    if(stat.value == 0)
      return null;

    return (
      <div style={{display: 'grid', gridTemplateColumns: '170px min-content 1fr', alignItems: 'center', padding: '5px', border: '1px solid #ffffff40', borderRadius: '10px'}}>
        <div style={{color: 'gray'}}>{label}</div>
        <div>{this.state.race.users[stat.idx]}</div>
        <div style={{textAlign: 'right'}}>{stat.value}</div>
      </div>
    )
  }

  renderStats() {
    if(this.state.loading)
      return <div>Loading...</div>

    if(!this.state.stats)
      return null;

    let stats = this.state.stats;

    let damageDealt = this.getHighestStat(stats.damage.dealt);
    let damageTaken = this.getHighestStat(stats.damage.taken);
    let damageShielded = this.getHighestStat(stats.damage.shielded);
    let usedBoosts = this.getHighestStat(stats.used.boosts);
    let usedBrakes = this.getHighestStat(stats.used.brakes);
    let usedRams = this.getHighestStat(stats.used.rams);
    let usedShields = this.getHighestStat(stats.used.shields);
    let dnf = this.getHighestStat(stats.dnf);

    return (
      <div className="flex-column">
        {this.renderStat('💥 Damage Dealt', damageDealt)}
        {this.renderStat('💥 Damage Taken', damageTaken)}
        {this.renderStat('🛡️ Damage Blocked', damageShielded)}
        {this.renderStat('🚀 Boosts Used', usedBoosts)}
        {this.renderStat('🛑 Brakes Used', usedBrakes)}
        {this.renderStat('🔰 Rams Used', usedRams)}
        {this.renderStat('🛡️ Shields Used', usedShields)}
        {this.renderStat('🚫 Most DNF', dnf)}
      </div>
    )
  }

  renderLog() {
    if(this.state.loading)
      return <div>Loading...</div>

    if(!this.state.log)
      return null;

    let users = this.state.race.users;

    let divs = [];
    for(let i = 0; i < this.state.log.action.length; i++) {
      let idx = this.state.log.action[i];
      let action = this.state.log.actions[idx];

      let username = <span style={{color: 'white'}}>{users[this.state.log.username[i]]}</span>;
      let aggressor = <span style={{color: 'white'}}>{users[this.state.log.aggressor[i]]}</span>;
      let target = <span style={{color: 'white'}}>{users[this.state.log.target[i]]}</span>;
      let health = <span style={{color: '#bbbb00', fontSize: '0.8em'}}>{Math.floor(this.state.log.health[i])}%</span>
      let damage = <span> for {this.state.log.damageTaken[i] > 0 ? this.state.log.damageTaken[i]: 'no'} damage</span>
      
      let msg = null;
      let icon = <div>🚀</div>;

      // console.log(action);

      if(action == 'Boost') {
        msg = <div>{username} {health} boosted</div>
        icon = <div>🚀</div>;
      }
      else if(action == 'Ram') {
        msg = <div>{username} {health} attempted ram against {target}</div>
        icon = <div>🔰</div>;
      }
      else if(action == 'RamSuccess') {
        msg = <div>{username} {health} rammed {target}{damage}</div>
        icon = <div>🔰</div>;
      }
      else if(action == 'BrakeTest') {
        msg = <div>{username} {health} attempted brake test against {target}</div>
        icon = <div>🛑</div>;
      }
      else if(action == 'BrakeSuccess') {
        msg = <div>{username} {health} brake tested {target}{damage}</div>
        icon = <div>🛑</div>;
      }
      else if(action == 'DeployShield') {
        msg = <div>{username} {health} activated shields</div>
        icon = <div>🛡️</div>;
      }
      else if(action == 'WorldCollision') {
        msg = <div>{username} {health} hits an obstacle{damage}</div>
        icon = <div>💥</div>;
      }
      else if(action == 'CarCollision') {
        msg = <div>{username} {health} collides with another car{damage}</div>
        icon = <div>💥</div>;
      }
      else if(action == 'Undertake') {
        msg = <div>{username} {health} undertakes {aggressor}</div>
        icon = <div>⤵️</div>;
      }
      else if(action == 'Overtake') {
        msg = <div>{username} {health} overtakes {aggressor}</div>
        icon = <div>⤴️</div>;
      }
      else if(action == 'OutOfBoundsFall') {
        msg = <div>{username} {health} falls out of bounds</div>
        icon = <div>🚫</div>;
      }
      else if(action == 'OutOfBoundsSky') {
        msg = <div>{username} {health} flies out of bounds</div>
        icon = <div>🚫</div>;
      }
      else if(action == 'DNF') {
        msg = <div>{username} {health} is out of the race</div>
        icon = <div>🚫</div>;
      }
      else if(action == 'Crashed') {
        msg = <div>{username} {health} crashed</div>
        icon = <div>🚫</div>;
      }
      else if(action == 'RaceEnd') {
        msg = <div>{username} {health} finished the race</div>
        icon = <div>🏅</div>;
      }
      else
        console.warn('Unknown action', this.state.log.actions[idx]);

      if(!msg)
        continue;

      let time = this.state.log.raceTimeMilliseconds[i] / 1000;

      divs.push(
        <div key={i} className="flex-row" style={{alignItems: 'normal'}}>
          {icon}
          <div style={{color: 'gray', width: '100%'}}>{msg}</div>
          <div style={{width: '75px', color: 'gray', textAlign: 'right'}}>{time.toFixed(2)}s</div>
        </div>
      )
    }

    return (
      <div className="flex-column" style={{border: '1px solid #ffffff40', borderRadius: '10px', padding: '10px 10px 10px 5px'}}>
        {divs}
      </div>
    )
  }

  render() {
    if(this.state.navigate != '')
      return <Navigate to={this.state.navigate} />

    return (
      <div className="page">
        <div className="flex-column" style={{maxWidth: '600px', margin: 'auto'}}>
          {this.renderNoRaceFound()}
          {this.renderRaceHeader()}
          {this.renderTabs()}
          {this.state.tab == 0 && this.renderLeaderboard()}
          {this.state.tab == 1 && this.renderStats()}
          {this.state.tab == 2 && this.renderLog()}
        </div>
      </div>
    )
  }
}

export default RacePage;
