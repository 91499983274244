import React from 'react';
import { Database } from './services/Database';

interface DriverPageState {
  id: string;
}

class DriverPage extends React.Component<{}, DriverPageState> {
  constructor(props:{}) {
    super(props);
    this.state = {
      id: ''
    }
  }

  componentDidMount(): void {
    let parts = window.location.pathname.split('/');
    if(parts.length == 3) 
      this.setState({id: parts[2]})
  }

  render() {
    let character = Database.getDriver(this.state.id);

    let image = null;
    if(character)
      image = <img style={{width: '250px'}} src={'/images/drivers/' + character.id + '.png'} />

    return (
      <div className="page">
        <div className="flex-row">
          {image}
        </div>
      </div>
    )
  }
}

export default DriverPage;
