import React from 'react';
import './PrivacyPage.css';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-policy">
      <h1>Nova Rally Privacy Policy</h1>
      <p>Last updated: 15/09/2024</p>

      <h2>1. Introduction</h2>
      <p>Welcome to Nova Rally. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our game.</p>

      <h2>2. Information We Collect</h2>
      <h3>2.1 Personal Information</h3>
      <p>The only personal information we collect is what you provide through social logins. This may include:</p>
      <ul>
        <li>Your name</li>
        <li>Email address</li>
        <li>Profile picture</li>
      </ul>

      <h3>2.2 Non-Personal Information</h3>
      <p>We also collect non-personal information related to your game play, including:</p>
      <ul>
        <li>In-game character loadouts</li>
        <li>Car configurations</li>
        <li>Game progress and statistics</li>
      </ul>

      <h2>3. How We Use Your Information</h2>
      <p>We use the information we collect to:</p>
      <ul>
        <li>Provide and maintain our game service</li>
        <li>Improve user experience</li>
        <li>Authenticate your account</li>
        <li>Save your game progress and configurations</li>
      </ul>

      <h2>4. Cookies</h2>
      <p>We use cookies to enhance your gaming experience. Cookies are used for:</p>
      <ul>
        <li>Automatic login functionality</li>
        <li>Storing game preferences</li>
        <li>Saving in-game loadouts for characters and cars</li>
      </ul>
      <p>You can choose to disable cookies through your browser settings, but this may affect certain features of the game.</p>

      <h2>5. Data Security</h2>
      <p>We implement appropriate technical and organizational measures to protect your information against unauthorized access, alteration, disclosure, or destruction.</p>

      <h2>6. Third-Party Services</h2>
      <p>We may use third-party services for social login functionality. These services have their own privacy policies, and we encourage you to review them.</p>

      <h2>7. Children's Privacy</h2>
      <p>Our game is not intended for children under 13. We do not knowingly collect personal information from children under 13.</p>

      <h2>8. Changes to This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

      <h2>9. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at: contact@novarally.io</p>
    </div>
  );
};

export default PrivacyPolicy;