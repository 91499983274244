import React from 'react';
import { Navigate } from 'react-router-dom';
import { Account } from './services/Account';

interface GooglePageProps {
  onUpdate: Function;
}

interface GooglePageState {
  navigate: string;
}

class GooglePage extends React.Component<GooglePageProps, GooglePageState> {
  constructor(props: GooglePageProps) {
    super(props);
    this.state = {
      navigate: ''
    }
  }

  componentDidMount(): void {
    if (window.location.hash.indexOf('id_token=') != -1) {
      let idToken = window.location.hash.split('id_token=')[1].split('&')[0];
      this.login(idToken);
    }
  }

  async login(idToken: string) {
    setTimeout(async () => {
      await Account.continueLoginWithGoogle(idToken);
      this.props.onUpdate();
      
      let returnUrl = sessionStorage.getItem('loginReturnUrl') || '/';
      sessionStorage.removeItem('loginReturnUrl');
      
      this.setState({navigate: returnUrl});
    }, 1000);
  }
  
  render() {
    if (this.state.navigate != '')
      return <Navigate to={this.state.navigate} />
      
    return (
      <div className="page">
        <div className="dialog-cover">
          <div className="dialog-panel" style={{maxWidth: '250px', padding: '20px'}}>
            Logging in with Google...
          </div>
        </div>
      </div>
    );
  }
}

export default GooglePage;