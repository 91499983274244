import React from 'react';
import { PlayFab } from './services/PlayFab';
import { Database } from './services/Database';
import { Navigate } from 'react-router-dom';
import { Account } from './services/Account';

interface TestPageState {
  loading: boolean;
  rawResponses: {
    stats: any;
    carStats: any;
    characterStats: any;
  };
  error: string | null;
  username: string;
  navigate: string;
  activeTab: string;
}

interface CharacterData {
  id: number;
  xp: number;
}

interface CarData {
  id: number;
  xp: number;
}


// const TabSelector: React.FC<TabProps> = ({ activeTab, setActiveTab }) => (
//   <div style={{
//     display: 'flex',
//     justifyContent: 'center',
//     marginBottom: '20px',
//   }}>
//     {['summary', 'characters', 'cars'].map((tab) => (
//       <button
//         key={tab}
//         onClick={() => setActiveTab(tab)}
//         style={{
//           padding: '10px 20px',
//           backgroundColor: activeTab === tab ? '#FFD700' : '#2a4086',
//           color: activeTab === tab ? '#000' : '#fff',
//           border: 'none',
//           borderRadius: tab === 'summary' ? '5px 0 0 5px' : tab === 'cars' ? '0 5px 5px 0' : '0',
//           cursor: 'pointer',
//           fontSize: '16px',
//           fontWeight: 'bold',
//           transition: 'background-color 0.3s, color 0.3s',
//           outline: 'none',
//           boxShadow: activeTab === tab ? '0 0 10px rgba(255, 215, 0, 0.5)' : 'none',
//         }}
//       >
//         {tab.charAt(0).toUpperCase() + tab.slice(1)}
//       </button>
//     ))}
//   </div>
// );

class StatsPage extends React.Component<{}, TestPageState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      loading: true,
      rawResponses: {
        stats: null,
        carStats: null,
        characterStats: null
      },
      error: null,
      username: '',
      navigate: '',
      activeTab: 'summary'
    }
  }

  componentDidMount(): void {
    this.loadPage();
  }

  async loadPage() {
    this.setState({loading: true, error: null});
    try {
      const [statsResponse, carStatsResponse, characterStatsResponse] = await Promise.all([
        PlayFab.executeCloudScript('getPlayerSeasonStats'),
        PlayFab.executeCloudScript('getPlayerSeasonCarStats'),
        PlayFab.executeCloudScript('getPlayerSeasonCharacterStats')
      ]);

      this.setState({
        rawResponses: {
          stats: statsResponse,
          carStats: carStatsResponse,
          characterStats: characterStatsResponse,
        },
        loading: false
      });

      let playerId = await PlayFab.getPlayerId();
      if (playerId) {
        this.setState({username: playerId});
      } else {
        console.error('Failed to fetch player ID');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      this.setState({error: 'Error fetching data: ' + JSON.stringify(error), loading: false});
    }
  }

  formatTime(milliseconds: number): string {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const remainingMs = milliseconds % 1000;
    return `${minutes}m ${seconds}.${remainingMs.toString().padStart(3, '0')}s`;
  }

  renderTabSelector() {
    const tabs = ['summary', 'characters', 'cars'];
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
        flexWrap: 'wrap', // Allow wrapping on smaller screens
      }}>
        {tabs.map((tab, index) => (
          <div
            key={tab}
            onClick={() => this.setActiveTab(tab)}
            style={{
              padding: '10px 15px',
              backgroundColor: this.state.activeTab === tab ? '#3856B0' : '#FFA500',
              color: '#ffffff',
              cursor: 'pointer',
              fontSize: '18px', // Increased from 16px to 18px
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: '1px',
              borderRadius: '5px',
              margin: '5px',
              flexGrow: 1,
              textAlign: 'center',
              minWidth: '100px', // Ensure buttons have a minimum width
              boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
              transition: 'background-color 0.3s ease',
            }}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </div>
        ))}
      </div>
    );
  }

  renderSummaryBoxes() {
    const boxStyle: React.CSSProperties = {
      backgroundColor: '#2a4086',
      border: '1px solid #ffffff40',
      borderRadius: '10px',
      padding: '15px',
      margin: '10px',
      width: 'calc(33.33% - 20px)',
      textAlign: 'center',
      color: '#ffffff',
    };
    const labelStyle: React.CSSProperties = {
      fontSize: '1.2em',
      marginBottom: '5px',
    };
    const valueStyle: React.CSSProperties = {
      fontSize: '1.5em',
      fontWeight: 'bold',
    };
    return (
      <>
        <h1 style={{
          textAlign: 'center',
          marginBottom: '30px',
          color: '#ffffff',
        }}>Player Summary</h1>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginBottom: '30px' }}>
          <div style={boxStyle}>
            <div style={labelStyle}>Fastest Time</div>
            <div style={valueStyle}>{this.formatTime(this.state.rawResponses.stats.data.time[1])}</div>
          </div>
          <div style={boxStyle}>
            <div style={labelStyle}>Total Races</div>
            <div style={valueStyle}>{this.state.rawResponses.stats.data.drivers[1].reduce((a: number, b: number) => a + b, 0)}</div>
          </div>
          <div style={boxStyle}>
            <div style={labelStyle}>Wins</div>
            <div style={valueStyle}>{this.state.rawResponses.stats.data.win[1]}</div>
          </div>
          <div style={boxStyle}>
            <div style={labelStyle}>Podiums</div>
            <div style={valueStyle}>{this.state.rawResponses.stats.data.podium[1]}</div>
          </div>
          <div style={boxStyle}>
            <div style={labelStyle}>Damage Dealt</div>
            <div style={valueStyle}>{this.state.rawResponses.stats.data.dealt[1]}</div>
          </div>
          <div style={boxStyle}>
            <div style={labelStyle}>Damage Received</div>
            <div style={valueStyle}>{this.state.rawResponses.stats.data.taken[1]}</div>
          </div>
        </div>
      </>
    );
  }

  renderXPBar(xp: number) {
    const level = Math.floor(xp / 1000);
    const progress = (xp % 1000) / 10;
    const percentage = Math.floor(progress);

    return (
      <div style={{ marginTop: '10px' }}>
        <div style={{ fontSize: '1.2em', textAlign: 'center', marginBottom: '5px' }}>
          Level {level}
        </div>
        <div style={{ 
          width: '100%', 
          backgroundColor: '#2a4086', 
          borderRadius: '5px', 
          overflow: 'hidden',
          position: 'relative',
        }}>
          <div style={{
            width: `${progress}%`,
            height: '20px',
            backgroundColor: '#FFD700',
          }}></div>
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontWeight: 'bold',
            textShadow: '1px 1px 2px black',
          }}>
            {percentage}%
          </div>
        </div>
      </div>
    );
  }

  renderCharacterCard(characterId: number, xp: number) {
    const { rawResponses } = this.state;
    const characterStats = rawResponses.characterStats?.data;

    if (!characterStats) return null;

    const character = Database.getDriverByServerId(characterId);
    if (!character) return null;

    const stats = [
      { label: 'Driver DNF', value: characterStats.driverDnf[characterId] },
      { label: 'Driver Podiums', value: characterStats.driverPodiums[characterId] },
      { label: 'Driver Races', value: characterStats.driverRaces[characterId] },
      { label: 'Driver Wins', value: characterStats.driverWins[characterId] },
      { label: 'Fastest Time', value: this.formatTime(characterStats.fastestTime[characterId]) },
      { label: 'Passenger DNF', value: characterStats.passengerDnf[characterId] },
      { label: 'Passenger Podiums', value: characterStats.passengerPodiums[characterId] },
      { label: 'Passenger Races', value: characterStats.passengerRaces[characterId] },
      { label: 'Passenger Wins', value: characterStats.passengerWins[characterId] },
      { label: 'Current Podium Streak', value: characterStats.podiumStreak[0][characterId] },
      { label: 'Best Podium Streak', value: characterStats.podiumStreak[1][characterId] },
      { label: 'Current Win Streak', value: characterStats.winStreak[0][characterId] },
      { label: 'Best Win Streak', value: characterStats.winStreak[1][characterId] },
    ];

    return (
      <div style={{
        width: 'calc(25% - 20px)',
        minWidth: '250px',
        border: '1px solid #ffffff40',
        borderRadius: '10px',
        backgroundColor: '#3856B0',
        margin: '10px',
        overflow: 'hidden',
        fontFamily: 'inherit',
      }}>
        <div style={{
          position: 'relative',
          paddingBottom: '100%',
          backgroundColor: '#2a4086',
        }}>
          <img 
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }} 
            src={`/images/drivers/${character.id}.png`} 
            alt={character.name} 
            onClick={() => this.setState({navigate: `/driver/${character.id}`})}
          />
          <div style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            padding: '10px',
            backgroundColor: 'rgba(0,0,0,0.7)',
            color: '#ffffff',
            fontSize: '1.4em',
            textAlign: 'center',
          }}>
            {character.name}
          </div>
        </div>
        {this.renderXPBar(xp)}
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '8px',
          padding: '12px',
          fontSize: '0.9em',
          color: '#ffffffcc',
        }}>
          {stats.map((stat, statIndex) => (
            <div key={statIndex} style={{ 
              borderBottom: '1px solid #ffffff20',
              paddingBottom: '4px'
            }}>
              <div style={{ fontWeight: 'bold', color: '#FFD700', marginBottom: '2px' }}>{stat.label}</div>
              <div>{stat.value ?? 'N/A'}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderCarCard(carId: number, carXP: number) {
    const { rawResponses } = this.state;
    const carStats = rawResponses.carStats?.data;
  
    if (!carStats) return null;
  
    const car = Database.getCarByServerId(carId);
    if (!car) return null;
  
    const stats = [
      { label: 'DNF', value: carStats.carDnf[carId] },
      { label: 'Podiums', value: carStats.carPodiums[carId] },
      { label: 'Races', value: carStats.carRaces[carId] },
      { label: 'Wins', value: carStats.carWins[carId] },
      { label: 'Fastest Time', value: this.formatTime(carStats.fastestTime[carId]) },
      { label: 'Current Podium Streak', value: carStats.carPodiumStreak[0][carId] },
      { label: 'Best Podium Streak', value: carStats.carPodiumStreak[1][carId] },
      { label: 'Current Win Streak', value: carStats.carWinStreak[0][carId] },
      { label: 'Best Win Streak', value: carStats.carWinStreak[1][carId] },
    ];
  
    return (
      <div style={{
        width: 'calc(25% - 20px)',
        minWidth: '250px',
        border: '1px solid #ffffff40',
        borderRadius: '10px',
        backgroundColor: '#3856B0',
        margin: '10px',
        overflow: 'hidden',
        fontFamily: 'inherit',
      }}>
        <div style={{
          position: 'relative',
          paddingBottom: '75%',
          backgroundColor: '#2a4086',
        }}>
          <img 
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }} 
            src={`/images/cars/${car.id}.png`} 
            alt={car.name} 
            onClick={() => this.setState({navigate: `/car/${car.id}`})}
          />
          <div style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            padding: '10px',
            backgroundColor: 'rgba(0,0,0,0.7)',
            color: '#ffffff',
            fontSize: '1.4em',
            textAlign: 'center',
          }}>
            {car.name}
          </div>
        </div>
        {this.renderXPBar(carXP)}
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '8px',
          padding: '12px',
          fontSize: '0.9em',
          color: '#ffffffcc',
        }}>
          {stats.map((stat, statIndex) => (
            <div key={statIndex} style={{ 
              borderBottom: '1px solid #ffffff20',
              paddingBottom: '4px'
            }}>
              <div style={{ fontWeight: 'bold', color: '#FFD700', marginBottom: '2px' }}>{stat.label}</div>
              <div>{stat.value ?? 'N/A'}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  setActiveTab = (tab: string) => {
    this.setState({ activeTab: tab });
  }

  render() {
    const { loading, error, rawResponses, navigate, activeTab } = this.state;
  
    if (!Account.isLoggedIn()) {
      return <Navigate to="/" />;
    }
  
    if (navigate !== '') {
      return <Navigate to={navigate} />
    }
  
    if(loading) {
      return <div style={{
        textAlign: 'center',
        fontSize: '1.5em',
        marginTop: '50px',
        color: '#ffffff',
      }}>Loading...</div>;
    }
  
    if (error) {
      return <div style={{
        textAlign: 'center',
        fontSize: '1.5em',
        marginTop: '50px',
        color: '#ff6b6b',
      }}>Error: {error}</div>;
    }
  
    const characterData: CharacterData[] = rawResponses.characterStats?.data?.characterXp
      .map((xp: number, index: number) => ({ id: index, xp }))
      .sort((a: CharacterData, b: CharacterData) => b.xp - a.xp) || [];
  
    const carData: CarData[] = rawResponses.carStats?.data?.carXp
      .map((xp: number, index: number) => ({ id: index, xp }))
      .sort((a: CarData, b: CarData) => b.xp - a.xp) || [];
  
    return (
      <div style={{
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '20px',
        fontFamily: 'inherit',
        color: '#ffffff',
        backgroundColor: '#1a2a4a',
      }}>
        {this.renderTabSelector()}
        {activeTab === 'summary' && (
          <>
            {this.renderSummaryBoxes()}
          </>
        )}
        {activeTab === 'characters' && (
          <>
            <h1 style={{
              textAlign: 'center',
              margin: '30px 0',
              color: '#ffffff',
              fontSize: '2.5em',
              fontWeight: 'normal',
              textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
            }}>Character Race Statistics</h1>
            <div style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}>
              {characterData.map((character: CharacterData) => (
                <React.Fragment key={character.id}>
                  {this.renderCharacterCard(character.id, character.xp)}
                </React.Fragment>
              ))}
            </div>
          </>
        )}
        {activeTab === 'cars' && (
          <>
            <h1 style={{
              textAlign: 'center',
              margin: '30px 0',
              color: '#ffffff',
              fontSize: '2.5em',
              fontWeight: 'normal',
              textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
            }}>Car Race Statistics</h1>
            <div style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}>
              {carData.map((car: CarData) => (
                <React.Fragment key={car.id}>
                  {this.renderCarCard(car.id, car.xp)}
                </React.Fragment>
              ))}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default StatsPage;