import React from 'react';
import { Database } from './services/Database';
import { NavLink, Navigate } from 'react-router-dom';
import './RacesPage.css';
import { Races } from './services/Races';
import { PlayFab } from './services/PlayFab';

interface RacesPageState {
  user: string;
  searchUser: string;
  loading: boolean;
  races: any[];
  navigate: string;
  hasSearched: boolean;
}

const formatTimelineTime = (milliseconds: number): string => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

const getTimelinePositionColor = (position: number): string => {
  switch (position) {
    case 1: return '#FFD700'; // Gold
    case 2: return '#C0C0C0'; // Silver
    case 3: return '#CD7F32'; // Bronze
    default: return '#FFFFFF'; // White
  }
};

const RaceTimeline: React.FC<{ races: any[], searchUser: string }> = ({ races, searchUser }) => {
  const lastTenRaces = races.slice(0, 10);

  return (
    <div style={{
      marginTop: '20px',
      marginBottom: '20px',
      overflowX: 'auto',
      paddingBottom: '20px',
      WebkitOverflowScrolling: 'touch',
    }}>
      <h3 style={{ textAlign: 'center', color: 'white' }}>Last 10 Races</h3>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        minWidth: 'min-content',
        padding: '20px 0',
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-start',
          maxWidth: '100%',
          overflowX: 'auto',
          padding: '0 20px',
        }}>
          {lastTenRaces.map((race, index) => {
            const ui = race.users.findIndex((user: string) => user.toLowerCase() === searchUser.toLowerCase());
            const position = ui !== -1 ? race.finish[ui] : 0;
            const time = ui !== -1 && race.times[ui] ? formatTimelineTime(race.times[ui]) : 'DNF';
            
            const driver = ui !== -1 ? Database.getDriverByServerId(race.drivers[ui]) : null;
            const passenger = ui !== -1 ? Database.getDriverByServerId(race.passengers[ui]) : null;
            const car = ui !== -1 ? Database.getCarByServerId(race.cars[ui]) : null;

            return (
              <div key={race.id} style={{
                position: 'relative',
                marginRight: '20px',
                flexShrink: 0,
                width: '150px',
                paddingTop: '40px',
              }}>
                <div style={{
                  position: 'absolute',
                  top: '0',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: '36px',
                  height: '36px',
                  borderRadius: '50%',
                  backgroundColor: getTimelinePositionColor(position),
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  color: '#000000',
                  fontSize: '1.1em',
                  border: '2px solid white',
                }}>
                  {position === 0 ? 'DNF' : position}
                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  background: '#3856B0',
                  padding: '10px',
                  borderRadius: '5px',
                  boxShadow: '0 0 0 2px rgba(255, 255, 255, 0.2)',
                }}>
                  {car && (
                    <img 
                      src={`/images/icons/CarIcons/${car.id}-icon.png`} 
                      alt={car.name} 
                      title={car.name} 
                      style={{width: '40px', height: '40px', marginBottom: '5px'}}
                    />
                  )}
                  <div style={{display: 'flex', justifyContent: 'center', marginBottom: '5px'}}>
                    {driver && (
                      <img 
                        src={`/images/icons/CharacterIcons/${driver.id}-icon.png`} 
                        alt={driver.name} 
                        title={driver.name}
                        style={{width: '30px', height: '30px', marginRight: '5px', borderRadius: '50%'}}
                      />
                    )}
                    {passenger && (
                      <img 
                        src={`/images/icons/CharacterIcons/${passenger.id}-icon.png`} 
                        alt={passenger.name} 
                        title={passenger.name}
                        style={{width: '30px', height: '30px', borderRadius: '50%'}}
                      />
                    )}
                  </div>
                  <div style={{fontSize: '0.9em', color: '#ccc'}}>
                    {time}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

class RacesPage extends React.Component<{}, RacesPageState> {
  constructor(props:{}) {
    super(props);
    this.state = {
      user: '',
      searchUser: '',
      loading: false,
      races: [],
      navigate: '',
      hasSearched: false
    }
  }

  componentDidMount() {
    this.loadAllRaces();
  }

  loadAllRaces = async () => {
    this.setState({ loading: true });
    try {
      const response = await Races.getRaces({});
      if (response.success) {
        this.setState({ races: response.body.races });
      }
    } catch (error) {
      console.error("Error loading races:", error);
    } finally {
      this.setState({ loading: false });
    }
  }

  onSearch = async () => {
    this.setState({ loading: true, hasSearched: false });
    try {
      // First, get the PlayFab ID using the display name
      const userInfoResponse = await PlayFab.getUserAccountInfoByDisplayName(this.state.user);
      
      if (!userInfoResponse.success) {
        console.error("Error fetching user info:", userInfoResponse.error);
        this.setState({ races: [], hasSearched: true });
        return;
      }

      const playFabId = userInfoResponse.accountInfo.PlayFabId;

      // Now, use the display name to fetch races
      const response = await Races.getRaces({ user: this.state.user });
      
      if (response.success) {
        this.setState({
          races: response.body.races,
          searchUser: this.state.user,
          hasSearched: true
        });
      } else {
        console.error("Error fetching races");
        this.setState({ races: [], hasSearched: true });
      }
    } catch (error) {
      console.error("Error searching races:", error);
      this.setState({ races: [], hasSearched: true });
    } finally {
      this.setState({ loading: false });
    }
  }

  doesUserMatchSearch(user:string) {
    if(this.state.user == '')
      return false;
    return (user.toLowerCase().indexOf(this.state.user.toLowerCase()) != -1);
  }

  sortLeaderboard(lb:any[]) {
    lb.sort((a, b)=>{
      if(a.place == 0 && b.place != 0)
        return 1;
      else if(a.place != 0 && b.place == 0)
        return -1;

      if(a.place < b.place)
        return -1;
      else if(a.place > b.place)
        return 1;
      
      return 0;
    });    
  }

  onRaceClick(id:string) {
    this.setState({navigate: '/race/' + id});
  }

  render() {
    if(this.state.navigate != '')
      return <Navigate to={this.state.navigate} />
      
    let divs = [];
    let lastDate:Date = new Date(0);
    let lastHost = '';
    let key = 0;

    for(let i = 0; i < this.state.races.length; i++) {
      let race = this.state.races[i];
      
      let ui = -1;
      for(let j = 0; j < race.users.length; j++)
        if(race.users[j].toLowerCase() == this.state.searchUser.toLowerCase())
          ui = j;

      if(ui == -1) {
        let lowest = 1000;
        for(let j = 0; j < race.users.length; j++) {
          if(race.finish[j] != 0 && race.finish[j] < lowest) {
            lowest = race.finish[j];
            ui = j;
          }
        }
      }

      if(ui == -1)
        ui = 0;

      let d = new Date(race.date);

      if(!lastDate || d.getDay() != lastDate.getDay() || lastHost == '' || lastHost != race.host) {
        lastDate = d;
        lastHost = race.host;
        divs.push(
          <div key={key++} className="flex-row" style={{justifyContent: 'space-between', backgroundColor: '#ffffff20', marginTop: '0px', padding: '10px', borderRadius: '10px'}}>
            <div>
              <div style={{color: 'gray', fontSize: '0.8em'}}>Date</div>
              <div>{d.toLocaleDateString()}</div>
            </div>
            <div>
              <div style={{color: 'gray', fontSize: '0.8em', textAlign: 'right'}}>Host</div>
              <div>{race.host}</div>
            </div>
          </div>
        )
      }

      let car = Database.getCarByServerId(race.cars[ui]);
      let driver = Database.getDriverByServerId(race.drivers[ui]);
      let passenger = Database.getDriverByServerId(race.passengers[ui]);

      let finish = null;

      let pos = '';
      if(race.finish[ui] == 0)
        pos = 'DNF';
      else if(race.finish[ui] == 1)
        pos = '1st';
      else if(race.finish[ui] == 2)
        pos = '2nd';
      else if(race.finish[ui] == 3)
        pos = '3rd'
      else
        pos = race.finish[ui] + 'th';

      let width = '70px';
      if(this.state.searchUser == '') {
        width = '100px';
        pos = race.users[ui];
      }

      finish = (
        <div className="flex-column" style={{rowGap: '0px', justifyContent: 'center', alignItems: 'center', width, height: '60px'}}>
          <div style={{width: '100%', textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{pos}</div>
          <div style={{fontSize: '0.9em', color: 'gray'}}>{(race.times[ui]/1000).toFixed(2) + 's'}</div>
        </div>
      )

      let track = 'Canyon Chaos';

      divs.push(
        <div key={key++} className="flex-row" style={{justifyContent: 'space-between',border: '1px solid #ffffff40', borderRadius: '10px'}}>
          <div className="flex-row" style={{padding: '5px'}}>
            {finish}
            <img 
              style={{height: '60px', borderRadius: '6px', cursor: 'pointer'}} 
              src={`/images/cars/${car.id}.png`} 
              title={race.car} 
              onClick={()=>this.setState({navigate: '/car/' + car.id})}
            />
            <img 
              style={{height: '60px', borderRadius: '6px', cursor: 'pointer'}} 
              src={`/images/drivers/${driver.id}.png`} 
              title={driver.name} 
              onClick={()=>this.setState({navigate: '/driver/' + driver.id})}
            />
            <img 
              style={{height: '60px', borderRadius: '6px', cursor: 'pointer'}} 
              src={`/images/drivers/${passenger.id}.png`} 
              title={passenger.name} 
              onClick={()=>this.setState({navigate: '/driver/' + passenger.id})}
            />
            <div className="races-page-track-time">
              <div>{track}</div>
              <div style={{fontSize: '0.9em', color: 'gray'}}>{d.toLocaleTimeString()}</div>
            </div>
          </div>
          <NavLink to={'/race/' + race.id}>
            <img style={{width: '25px', paddingRight: '15px'}} src="/images/view.png" />
          </NavLink>
        </div>
      )
    }

    if(this.state.loading)
      divs = [<div key={0}>Loading...</div>]
    else if(this.state.races.length == 0)
      divs = [<div key={0}>No races on record for that player.</div>]

    return (
      <div className="page">
        <div className="flex-column" style={{maxWidth: '100%', margin: 'auto', padding: '0 20px'}}>
          <div style={{display: 'grid', gridTemplateColumns: '1fr min-content', width: '100%', columnGap: '10px', maxWidth: '600px', margin: 'auto'}}>
            <input 
              placeholder="Enter player name..." 
              value={this.state.user} 
              onChange={(e:any)=>this.setState({user: e.currentTarget.value})} 
            />
            <button onClick={this.onSearch}>Search</button>
          </div>
          
          {this.state.hasSearched && this.state.races.length > 0 && (
            <RaceTimeline races={this.state.races} searchUser={this.state.searchUser} />
          )}
          
          <div className="flex-column" style={{maxWidth: '600px', margin: 'auto', width: '100%'}}>
            {divs}
          </div>
        </div>
      </div>
    )
  }
}

export default RacesPage;