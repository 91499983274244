import React from 'react';
import './TermsPage.css';


const TermsOfService: React.FC = () => {
  return (
    <div className="terms-of-service">
      <h1>Nova Rally Terms of Service</h1>
      <p>Last Updated: 15/09/2024</p>

      <h2>1. Acceptance of Terms</h2>
      <p>By accessing or using Nova Rally (the "Game"), you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not use the Game.</p>

      <h2>2. Changes to Terms</h2>
      <p>We reserve the right to modify these Terms at any time. We will provide notice of any material changes by updating the "Last Updated" date at the top of this page. Your continued use of the Game after any such changes constitutes your acceptance of the new Terms.</p>

      <h2>3. Privacy Policy</h2>
      <p>Your use of the Game is also governed by our Privacy Policy, which is incorporated into these Terms by reference.</p>

      <h2>4. User Accounts</h2>
      <p>4.1. You may need to create an account to use some features of the Game. You are responsible for maintaining the confidentiality of your account information.</p>
      <p>4.2. You agree to provide accurate and complete information when creating your account and to update this information as necessary.</p>
      <p>4.3. You are solely responsible for all activities that occur under your account.</p>

      <h2>5. User Conduct</h2>
      <p>5.1. You agree not to use the Game for any unlawful purpose or in any way that violates these Terms.</p>
      <p>5.2. Prohibited activities include, but are not limited to:</p>
      <ul>
        <li>Cheating, hacking, or exploiting game mechanics</li>
        <li>Harassing or bullying other players</li>
        <li>Impersonating other players or Nova Rally staff</li>
        <li>Distributing spam or malware</li>
        <li>Attempting to gain unauthorized access to other user accounts or game systems</li>
      </ul>

      <h2>6. Intellectual Property</h2>
      <p>6.1. All content in the Game, including but not limited to text, graphics, logos, images, and software, is the property of Nova Rally or its licensors and is protected by copyright and other intellectual property laws.</p>
      <p>6.2. You may not copy, modify, distribute, sell, or lease any part of our Game or included software, nor may you reverse engineer or attempt to extract the source code of the software, unless you have our written permission.</p>

      <h2>7. In-Game Purchases and Virtual Currency</h2>
      <p>7.1. The Game may offer virtual items or currency for purchase with real money. These virtual items and currency have no real-world value and cannot be exchanged for cash or any other tangible goods.</p>
      <p>7.2. All purchases are final and non-refundable, except as required by applicable law.</p>

      <h2>8. Termination</h2>
      <p>8.1. We reserve the right to suspend or terminate your account and access to the Game at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users, us, or third parties, or for any other reason.</p>

      <h2>9. Disclaimer of Warranties</h2>
      <p>9.1. The Game is provided "as is" and "as available" without any warranties, either express or implied.</p>
      <p>9.2. We do not guarantee that the Game will always be available, uninterrupted, or error-free.</p>

      <h2>10. Limitation of Liability</h2>
      <p>To the fullest extent permitted by applicable law, Nova Rally shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from your access to or use of or inability to access or use the Game.</p>

      <h2>11. Governing Law</h2>
      <p>These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.</p>

      <h2>12. Contact Information</h2>
      <p>If you have any questions about these Terms, please contact us at: contact@novarally.io</p>
    </div>
  );
};

export default TermsOfService;