import React from 'react';
import { Database } from './services/Database';
import { Navigate, NavLink } from 'react-router-dom';
import { Account } from './services/Account';

class CarsPage extends React.Component {
  render() {
    if(!Account.isDeveloperLoggedIn())
      return <Navigate to="/" replace />

    let divs = [];
    for(let i = 0; i < Database.cars.length; i++) {
      let car = Database.cars[i];
      let src = `/images/cars/${car.id}.png`;
      divs.push(
        <div key={i}>
          <NavLink to={'/car/' + car.id}>
            <img style={{width: '100px'}} src={src} />
          </NavLink>
        </div>
      )
    }
    
    return (
      <div className="page">
        <div className="flex-row" style={{flexWrap: 'wrap'}}>
          {divs}
        </div>
      </div>
    )
  }
}

export default CarsPage;
