import React from 'react';
import './HomePage.css';
import { NavLink } from 'react-router-dom';
import { Database } from './services/Database';

// Add these interfaces at the top of your file
interface Character {
  id: string;
  name: string;
  description: string;
  // Add other properties as needed
}

interface Car {
  id: string;
  name: string;
  // Add other properties as needed
}

class HomePage extends React.Component {

  renderVideoPanel(title: string, src: string, text: string) {
    return (
      <div style={{
        width: '100%',
        fontSize: '1.0em',
        textAlign: 'center',
        border: '1px solid #ffffff40',
        borderRadius: '10px',
        padding: '15px',
        backgroundColor: '#3856B0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
      }}>
        <div style={{
          fontSize: '1.1em',
          textAlign: 'center',
          border: '1px solid #ffffff40',
          padding: '5px',
          borderRadius: '5px',
          backgroundColor: '#FFA500', // Orange background for heading
          color: '#FFFFFF', // White text for contrast
          marginBottom: '10px',
        }}>
          {title}
        </div>
        <video
          autoPlay
          muted
          loop
          playsInline
          style={{
            width: '100%',
            borderRadius: '5px',
            objectFit: 'cover',
          }}
        >
          <source src={'videos/' + src} type="video/mp4" />
        </video>
        <div style={{
          padding: '5px',
          fontSize: '1.1em',
          height: '60px', // Fixed height for text area
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}>
          {text}
        </div>
      </div>
    )
  }

  renderInfoPanel(text: string, src: string) {
    return(
      <div style={{
        width: '100%',
        height: '400px', // Fixed height for all panels
        border: '1px solid #ffffff40',
        borderRadius: '10px',
        padding: '15px',
        backgroundColor: '#3856B0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
      }}>
        <div style={{fontSize: '1.1em', textAlign: 'center', height: '60px', overflow: 'hidden'}}>{text}</div>
        <div style={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}>
          <img 
            src={`images/${src}`} 
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              width: 'auto',
              height: 'auto',
              objectFit: 'contain',
              borderRadius: '6px'
            }} 
            alt={text} 
          />
        </div>
        <div style={{textAlign: 'center', marginTop: '10px'}}>
          <NavLink to="/play">
            <button style={{width: '200px'}}>Play Now</button>
          </NavLink>
        </div>
      </div>
    )
  }

  renderCharacterCard(character: Character) {
    return (
      <div className="character-card" style={{
        width: '220px',
        border: '1px solid #ffffff40',
        borderRadius: '10px',
        padding: '15px',
        backgroundColor: '#3856B0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '10px',
        cursor: 'pointer',
      }}>
        <div style={{
          width: '200px',
          height: '240px',
          backgroundColor: '#2a4086',
          borderRadius: '8px',
          overflow: 'hidden',
          marginBottom: '10px',
        }}>
          <img src={`images/drivers/${character.id}.png`} alt={character.name} style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }} />
        </div>
        <h3 style={{
          margin: '0 0 10px 0',
          color: '#ffffff',
          fontSize: '1.2em',
        }}>{character.name}</h3>
        <p style={{
          margin: '0',
          fontSize: '0.9em',
          color: '#ffffffcc',
          textAlign: 'center',
        }}>{character.description}</p>
      </div>
    );
  }

  renderCarCard(car: Car) {
    return (
      <div className="character-card" style={{
        width: '220px',
        border: '1px solid #ffffff40',
        borderRadius: '10px',
        padding: '15px',
        backgroundColor: '#3856B0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '10px',
        cursor: 'pointer',
      }}>
        <div style={{
          width: '200px',
          height: '150px',
          backgroundColor: '#2a4086',
          borderRadius: '8px',
          overflow: 'hidden',
          marginBottom: '10px',
        }}>
          <img src={`images/cars/${car.id}.png`} alt={car.name} style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }} />
        </div>
        <h3 style={{
          margin: '0 0 10px 0',
          color: '#ffffff',
          fontSize: '1.2em',
        }}>{car.name}</h3>
      </div>
    );
  }

  renderFooter() {
    return (
      <footer style={{ 
        padding: '20px', 
        textAlign: 'center', 
        borderTop: '1px solid #444',
        marginTop: '30px'
      }}>
        <NavLink to="/privacy-policy" className="footer-link" style={{ marginRight: '20px' }}>
          Privacy Policy
        </NavLink>
        <NavLink to="/terms-of-service" className="footer-link">
          Terms of Service
        </NavLink>
      </footer>
    );
  }

  render() {
    const characters: Character[] = Database.drivers;
    const cars: Car[] = Database.cars;

    return (
      <div className="flex-column" style={{rowGap: '0px'}}>
        <div style={{margin: 'auto'}}>
          <img className="home-page-header-image" src="images/splash.png" alt="Splash" />
        </div>
        
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
          gap: '20px',
          padding: '20px',
          justifyContent: 'center',
        }}>
          {this.renderInfoPanel('A Free To Play casual racing game where you pick two aliens and a car.', 'PickDrivers.png')}
          {this.renderInfoPanel('Watch them race autonomously, driven by their unique stats.', 'WatchRace.png')}
          {this.renderInfoPanel('Take control during the race to issue commands like speed boosts and ramming opponents.', 'TakeControl2.png')}
          {this.renderInfoPanel('Customise your vehicles with a variety of cosmetic upgrades!', 'select-paint.png')}
        </div>

        <div style={{textAlign: 'center', fontSize: '1.5em', marginTop: '10px'}}>How to Play</div>

        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
          gap: '20px',
          padding: '20px',
          justifyContent: 'center',
        }}>
          {this.renderVideoPanel('BOOST', 'BoostHomePage.mp4', 'A quick surge of speed, but can be dangerous!')}
          {this.renderVideoPanel('RAM', 'RamHomeWeb.mp4', 'Attack your opponents and knock them off the road!')}
          {this.renderVideoPanel('SHIELD', 'Shieldhomeweb.mp4', 'Protect yourself from damage for a few seconds')}
          {this.renderVideoPanel('CARS', 'CarsHome.mp4', 'Choose from a selection of 10 vehicles')}
          {this.renderVideoPanel('DRIVERS', 'Drivershome.mp4', '21 unique drivers with different driving styles')}
        </div>
        
        <div style={{textAlign: 'center', fontSize: '1.5em', marginTop: '30px', marginBottom: '20px'}}>
          Meet the Characters
        </div>

        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          maxWidth: '1320px',
          margin: '0 auto',
        }}>
          {characters.map((character: Character, index: number) => (
            <div key={index}>
              {this.renderCharacterCard(character)}
            </div>
          ))}
        </div>

        <div style={{textAlign: 'center', fontSize: '1.5em', marginTop: '30px', marginBottom: '20px'}}>
          Meet the Cars
        </div>

        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          maxWidth: '1320px',
          margin: '0 auto',
        }}>
          {cars.map((car: Car, index: number) => (
            <div key={index}>
              {this.renderCarCard(car)}
            </div>
          ))}
        </div>

        <div style={{height: "30px"}} />

        {this.renderFooter()}
      </div>
    )
  }
}

export default HomePage;