const clientId = '935168051863-fiu2h0cleqieu082meqt2l1lf0s9fbl8.apps.googleusercontent.com'; // Replace with your actual Google Client ID

export class Google {
  private static idToken: string = '';

  public static setIdToken(idToken: string) {
    Google.idToken = idToken;
  }

  public static clearIdToken() {
    Google.idToken = '';
  }

  public static login() {
    let url = `https://accounts.google.com/o/oauth2/v2/auth`;
    url += `?client_id=${clientId}`;
    url += `&redirect_uri=${encodeURIComponent(window.location.origin + '/google')}`;
    url += `&response_type=id_token`;
    url += `&scope=${encodeURIComponent('openid profile email')}`;
    url += `&nonce=${Math.random().toString(36).substring(2, 15)}`;
    window.location.href = url;
  }

  public static async getUser(): Promise<{success: boolean, body?: any, message?: string}> {
    return new Promise((resolve, reject) => {
      const url = `https://oauth2.googleapis.com/tokeninfo?id_token=${Google.idToken}`;
      var request = new XMLHttpRequest();
      request.open('GET', url);

      console.log('> GOOGLE /tokeninfo');

      request.send();

      request.onload = () => {
        let body = JSON.parse(request.responseText);
        console.log('< GOOGLE', body);
        
        if (request.status == 200) {
          resolve({ success: true, body });
        } else {
          resolve({ success: false, body });
        }
      };

      request.onerror = () => {
        reject({ success: false, message: "Network error" });
      };
    });
  }
}