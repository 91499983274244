// Define the InventoryResponse interface
export interface InventoryResponse {
  success: boolean;
  items?: any[];
  continuationToken?: string | null;
}

export class PlayFab {
  // Add this new property near the top of the class
  private static isInitialized: boolean = false;

  // Add this new method
  private static initialize() {
    if (!this.isInitialized) {
      this.loadSession();
      this.isInitialized = true;
    }
  }

  protected static titleId = '7A13D';
  protected static playerId = '';
  protected static sessionTicket = '';
  protected static entityToken = '';
  protected static entity: any = null;

  public static post(method: string, body: any): Promise<{ status: number, body?: any }> {
    this.initialize();
    return new Promise((resolve, reject) => {
      var request = new XMLHttpRequest();
      request.open('POST', `https://${PlayFab.titleId}.playfabapi.com/${method}`);
      request.setRequestHeader('Content-Type', 'application/json');

      if (PlayFab.sessionTicket != '')
        request.setRequestHeader('X-Authorization', PlayFab.sessionTicket);

      if (PlayFab.entityToken != '')
        request.setRequestHeader('X-EntityToken', PlayFab.entityToken);
      
      console.log(`> PLAYFAB /${method}`, body);
      
      let payload = JSON.stringify(body);
      request.send(payload);
  
      request.onload = () => {
        let responseBody = null;
        if (request.responseText != '')
          responseBody = JSON.parse(request.responseText);
  
        console.log('< PLAYFAB', responseBody);
  
        resolve({
          status: request.status,
          body: responseBody
        });
      };
  
      request.onerror = (e) => {
        console.log('< ERROR');
        resolve({
          status: 500,
          body: {message: 'Internal service error!'}
        });
      };
    });
  }

  public static async loginWithTwitch(accessToken: string): Promise<{ success: boolean }> {
    let response = await PlayFab.post('Client/LoginWithTwitch', {
      TitleId: PlayFab.titleId, 
      AccessToken: accessToken,
      CreateAccount: true
    });

    if (response.status == 200) {
      PlayFab.playerId = response.body.data.PlayFabId;
      PlayFab.sessionTicket = response.body.data.SessionTicket;
      PlayFab.entity = response.body.data.EntityToken.Entity;
      PlayFab.entityToken = response.body.data.EntityToken.EntityToken;
    }

    return { success: response.status == 200 };
  }

  public static async loginWithOpenIdConnect(idToken: string, provider: string): Promise<{ success: boolean }> {
    if (this.loadSession()) {
      return { success: true };
    }

    let response = await this.post('Client/LoginWithOpenIdConnect', {
      TitleId: this.titleId,
      ConnectionId: provider,
      IdToken: idToken,
      CreateAccount: true
    });

    if (response.status == 200) {
      this.playerId = response.body.data.PlayFabId;
      this.sessionTicket = response.body.data.SessionTicket;
      this.entity = response.body.data.EntityToken.Entity;
      this.entityToken = response.body.data.EntityToken.EntityToken;
      this.storeSession();
    }

    return { success: response.status == 200 };
  }

  public static async getInventory(continuationToken: string | null = null): Promise<InventoryResponse> {
    let body: any = {};
    if (continuationToken) {
      body.ContinuationToken = continuationToken;
    }

    let response = await PlayFab.post('Inventory/GetInventoryItems', body);

    if (response.status != 200) {
      return { success: false };
    }

    return {
      success: true,
      items: response.body.data.Items,
      continuationToken: response.body.data.ContinuationToken
    };
  }

  public static async getUserReadOnlyData(keys: string[]): Promise<{ success: boolean, data?: any }> {
    let response = await PlayFab.post('Client/GetUserReadOnlyData', { Keys: keys });

    if (response.status != 200)
      return { success: false };

    return { success: true, data: response.body.data.Data };
  }

  public static async executeCloudScript(func: string, params: any = {}): Promise<{ success: boolean, data?: any }> {
    let response = await PlayFab.post('Client/ExecuteCloudScript', { FunctionName: func, FunctionParameter: params, GeneratePlayStreamEvent: true });

    if (response.status != 200)
      return { success: false };

    return { success: true, data: response.body.data.FunctionResult };
  }

  public static getPlayerId(): string {
    this.initialize();
    return PlayFab.playerId;
  }

  public static async getAccountInfo(): Promise<{ status: number, body?: any }> {
    return PlayFab.post('Client/GetAccountInfo', {});
  }

  private static getSessionTicket(): string {
    // Implement this method to return the current PlayFab session ticket
    // This might be stored in localStorage or in memory after login
    return ''; // Placeholder return, replace with actual implementation
  }

  public static async getUserAccountInfoByDisplayName(displayName: string) {
    try {
      const response = await this.post('Client/GetAccountInfo', {
        TitleDisplayName: displayName
      });

      if (response.status === 200 && response.body?.data?.AccountInfo) {
        return {
          success: true,
          accountInfo: response.body.data.AccountInfo
        };
      } else {
        return {
          success: false,
          error: 'Failed to retrieve user account info'
        };
      }
    } catch (error) {
      console.error('Error in getUserAccountInfoByDisplayName:', error);
      return {
        success: false,
        error: 'An error occurred while fetching user account info'
      };
    }
  }

  private static storeSession() {
    const session = {
      playerId: this.playerId,
      sessionTicket: this.sessionTicket,
      entity: this.entity,
      entityToken: this.entityToken,
      timestamp: Date.now()
    };
    localStorage.setItem('PlayFabSession', JSON.stringify(session));
  }

  private static loadSession(): boolean {
    const sessionData = localStorage.getItem('PlayFabSession');
    if (sessionData) {
      const session = JSON.parse(sessionData);
      const currentTime = Date.now();
      // Check if the session is less than 23 hours old (giving 1 hour buffer)
      if (currentTime - session.timestamp < 23 * 60 * 60 * 1000) {
        this.playerId = session.playerId;
        this.sessionTicket = session.sessionTicket;
        this.entity = session.entity;
        this.entityToken = session.entityToken;
        return true;
      }
    }
    return false;
  }

  public static clearSession() {
    localStorage.removeItem('PlayFabSession');
    this.playerId = '';
    this.sessionTicket = '';
    this.entity = null;
    this.entityToken = '';
  }

  public static hasValidSession(): boolean {
    this.initialize();
    return !!this.entityToken;
  }

  public static async updateAvatarUrl(avatarUrl: string): Promise<boolean> {
    try {
      console.log("Updating avatar URL in PlayFab:", avatarUrl);
      const response = await this.post('Client/UpdateAvatarUrl', {
        ImageUrl: avatarUrl
      });

      if (response.status === 200) {
        console.log('Avatar URL updated successfully in PlayFab');
        return true;
      } else {
        console.error('Failed to update avatar URL in PlayFab:', response);
        return false;
      }
    } catch (error) {
      console.error('Error updating avatar URL in PlayFab:', error);
      return false;
    }
  }

  public static async getPlayerProfile(): Promise<any> {
    if (!this.hasValidSession()) {
      throw new Error('No valid PlayFab session');
    }

    try {
      console.log("Fetching player profile from PlayFab");
      const response = await this.post('Client/GetPlayerProfile', {
        PlayFabId: this.getPlayerId(),
        ProfileConstraints: {
          ShowDisplayName: true,
          ShowAvatarUrl: true
        }
      });

      if (response.status === 200 && response.body.data && response.body.data.PlayerProfile) {
        console.log("Player profile fetched successfully:", response.body.data.PlayerProfile);
        return response.body.data.PlayerProfile;
      } else {
        console.error('Failed to fetch player profile:', response);
        throw new Error('Failed to fetch player profile');
      }
    } catch (error) {
      console.error('Error fetching player profile:', error);
      throw error;
    }
  }
}

