import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Account } from './services/Account';
import { Network } from './services/Network';
import { PlayFab } from './services/PlayFab';

function LogoutPage() {
  const { logout } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    async function handleLogout() {
      console.log('Starting logout process');
      try {
        console.log('Clearing Auth0 session');
        await logout({ logoutParams: { returnTo: window.location.origin } });
        console.log('Auth0 session cleared');

        console.log('Clearing account state and local storage');
        Account.logout();
        console.log('Account state and local storage cleared');

        console.log('Disconnecting from network');
        Network.disconnect();
        console.log('Disconnected from network');
      } catch (error) {
        console.error('Error during logout:', error);
      }
      PlayFab.clearSession();
      console.log('Logout process completed');
    }

    handleLogout();

    console.log('Setting up redirect timer');
    const timer = setTimeout(() => {
      console.log('Redirect timer fired, navigating to home page');
      navigate('/');
    }, 5000);

    return () => {
      console.log('Cleaning up: clearing redirect timer');
      clearTimeout(timer);
    };
  }, [logout, navigate]);

  console.log('Rendering LogoutPage component');
  return (
    <div>
      <p>Logging out...</p>
      <p>Thanks for playing!</p>
      <p>Redirecting to home page in 5 seconds...</p>
    </div>
  );
}

export default LogoutPage;
