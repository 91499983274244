import React from 'react';
import { Races } from './services/Races';
import './StreamerPage.css';
import { Account } from './services/Account';
import { Navigate } from 'react-router-dom';

interface StreamerPageState {
  log: string;
  maxSeconds: number;
  seconds: number;
  paused: boolean;
  streamer: string;
  loading: boolean;
  resetting: boolean;
  error: string;
  raceCount: number;
  visible: boolean;
  leaderboards: any[];
  leaderboardIdx: number;
  resetLeaderboard: boolean;
}

class StreamerPage extends React.Component<{}, StreamerPageState> {
  protected timer:any;

  constructor(props:{}) {
    super(props);
    this.state = {
      log: '',
      maxSeconds: 60,
      seconds: 60,
      paused: false,
      streamer: '',
      loading: false,
      resetting: false,
      error: '',
      raceCount: 0,
      visible: true,
      leaderboards: [],
      leaderboardIdx: 0,
      resetLeaderboard: false
    }
    this.timer = null;
  }

  componentDidMount(): void {
    let savedStreamer = localStorage.getItem('StreamerId');
    if(savedStreamer)
      this.setState({streamer: savedStreamer});
  }

  setError(message:string) {
    this.setState({error: message, loading: false});
    setTimeout(() => {
      this.setState({error: ''})
    }, 1000);
  }

  onLoadLeaderboard() {
    if(this.state.error != '' || this.state.loading || this.state.resetting || this.state.streamer == '')
      return;

    localStorage.setItem('StreamerId', this.state.streamer);

    this.setState({loading: true, visible: true, leaderboardIdx: 0});

    setTimeout(async () => {
      let response = await Races.getHostLeaderboard(this.state.streamer);
      this.setState({loading: false});
      if(response.success)  {
        this.processLeaderboard(response.body.leaderboard);
        this.onStartTimer();
      }
      else {
        this.clearLeaderboard();
        this.setError('Unknown Host!');
      }
    }, 250);
  }

  createLeaderboard(users:string[], values:number[], descending:boolean):any[] {
    let entries = [];
    for(let i = 0; i < users.length; i++) 
      if(values[i] > 0)
        entries.push({name: users[i], value: values[i]});

    entries.sort((a, b)=>{
      if(a.value > b.value)
        return 1 * (descending ? -1 : 1);
      else if(a.value < b.value)
        return -1 * (descending ? -1 : 1);

      if(a.name > b.name)
        return 1;
      else if(a.name < b.name)
        return -1;

      return 0;
    });

    return entries;
  }

  processLeaderboard(leaderboard:any) {
    let actions = [];
    let times = [];
    for(let i = 0; i < leaderboard.users.length; i++) {
      actions.push(leaderboard.used.boosts[i] + leaderboard.used.brakes[i] + leaderboard.used.rams[i] + leaderboard.used.shields[i])
      times.push(+((leaderboard.times[i]/1000).toFixed(2)));
    }

    let leaderboards = [];
    leaderboards.push(this.createLeaderboard(leaderboard.users, leaderboard.points, true));
    leaderboards.push(this.createLeaderboard(leaderboard.users, times, false));
    leaderboards.push(this.createLeaderboard(leaderboard.users, leaderboard.damage.dealt, true));
    leaderboards.push(this.createLeaderboard(leaderboard.users, leaderboard.damage.taken, true));
    leaderboards.push(this.createLeaderboard(leaderboard.users, actions, true));
    leaderboards.push(this.createLeaderboard(leaderboard.users, leaderboard.dnf, true));

    let raceCount = leaderboard.races;

    this.setState({leaderboards, raceCount, leaderboardIdx: 0})
  }

  resetLeaderboard() {
    this.setState({resetLeaderboard: false});

    this.clearLeaderboard();
    this.setState({resetting: true});

    setTimeout(async () => {
      let response = await Races.resetHostLeaderboard(this.state.streamer);
      this.setState({resetting: false});
      if(!response.success) 
        this.setError('Unknown Host!');
    }, 250);
  }

  clearLeaderboard() {
    clearInterval(this.timer);
    this.setState({leaderboards: [], leaderboardIdx: 0, raceCount: 0, seconds: this.state.maxSeconds, paused: false});
  }

  onStartTimer() {
    clearInterval(this.timer);
    this.setState({seconds: this.state.maxSeconds})
    this.timer = setInterval(() => {
      let s = this.state.seconds;
      if(s > 0 && !this.state.paused) 
        s--;
      this.setState({seconds: s});
      if(s == 0) {
        clearInterval(this.timer);
        setTimeout(() => {
          this.setState({visible: false})
        }, 2000);
      }
    }, 1000);
  }

  onPauseTimer() {
    this.setState({paused: !this.state.paused});
  }

  onResetLeaderboard() {
    if(this.state.loading || this.state.resetting)
        return;

    this.setState({resetLeaderboard: true})
  }

  onStat(idx:number) {
    if(this.state.leaderboardIdx == idx)
      this.setState({leaderboardIdx: 0});
    else
      this.setState({leaderboardIdx: idx});
  }

  renderStatsPanel(title:string, idx:number, decimals:number = 0) {
    let bestName = <div>&nbsp;</div>
    let bestValue = <div>&nbsp;</div>

    if(idx < this.state.leaderboards.length && this.state.leaderboards[idx].length > 0) {
      bestName = this.state.leaderboards[idx][0].name;
      bestValue = this.state.leaderboards[idx][0].value.toFixed(decimals);
    }

    let titleColor = '#ffefcc';
    let nameColor = 'white';
    if(this.state.leaderboardIdx == idx) {
      titleColor = 'tomato';
      nameColor = 'tomato';
    }

    return (
      <div className="streamer-page-clock" onClick={()=>this.onStat(idx)}>
        <div style={{fontSize: '0.8em', color: titleColor}}>{title}</div>
        <div className="flex-row" style={{justifyContent: 'space-between', width: '98%'}}>
          <div style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', color: nameColor}}>{bestName}</div>
          <div style={{color: nameColor}}>{bestValue}</div>
        </div>
      </div>
    )    
  }

  renderResetLeaderboardDialog() {
    return (
      <div className="dialog-cover">
        <div className="dialog-panel" style={{width: '250px', textAlign: 'center'}}>
          <div style={{textAlign: 'center', marginBottom: '20px'}}>Are you sure you want to reset the leaderboard for {this.state.streamer}?</div>
          <div className="flex-row" style={{columnGap: '50px', margin: 'auto'}} >
            <button onClick={()=>this.setState({resetLeaderboard: false})}>No</button>
            <button onClick={()=>this.resetLeaderboard()}>Yes</button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    if(!Account.isDeveloperLoggedIn())
      return <Navigate to="/" replace />

    let divs = [];

    if(this.state.leaderboards.length > 0) {
      let leaderboard = this.state.leaderboards[this.state.leaderboardIdx];
      for(let i = 0; i < Math.min(leaderboard.length, 10); i++) {
        let fontSize = this.state.leaderboardIdx == 1 ? '0.8em' : '1.0em';
        let decimals = this.state.leaderboardIdx == 1 ? 2 : 0;
        divs.push(
          <div key={i} className="streamer-page-player">
            <div style={{width: '190px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{leaderboard[i].name}</div>
            <div style={{width: '60px', textAlign: 'right', fontSize}}>{leaderboard[i].value.toFixed(decimals)}</div>
          </div>
        )
      }
    }

    for(let i = divs.length; i < 10; i++)
      divs.push(<div key={10+i} className="streamer-page-player"></div>);

    let time = this.state.seconds == 0 ? 'GO!' : 'Starts in ' + this.state.seconds.toString() + 's';

    return (
      <div className="flex-row" style={{alignItems: 'stretch', backgroundColor: 'green', padding: '50px', columnGap: '20px', position: 'relative'}}>
        <div className="flex-column" style={{rowGap: '10px'}}>
          <div className="streamer-page-clock" onClick={()=>this.onStat(0)}>
            <div style={{color: '#ffefcc'}}>Race #{this.state.raceCount+1}</div>
            <div>{time}</div>
          </div>
          {this.renderStatsPanel('FASTEST RACE', 1, 2)}
          {this.renderStatsPanel('DAMAGE DEALT', 2)}
          {this.renderStatsPanel('DAMAGE TAKEN', 3)}
          {this.renderStatsPanel('ACTIONS', 4)}
          {this.renderStatsPanel('DID NOT FINISH', 5)}
        </div>
        <div className="flex-column" style={{rowGap: '8px'}}>
          {divs}
        </div>
        <div className="flex-column" style={{paddingLeft: '100px'}}>
          <input placeholder="Streamer handle" value={this.state.streamer} onChange={(e:any)=>this.setState({streamer: e.currentTarget.value})} />
          <button onClick={()=>this.onLoadLeaderboard()}>
            {this.state.loading ? 'Loading...' : this.state.error != '' ? this.state.error : 'Load Leaderboard'}
          </button>
          <button onClick={()=>this.onResetLeaderboard()}>
            {this.state.resetting ? 'Resetting...' : this.state.error != '' ? this.state.error : 'Reset Leaderboard'}
          </button>
          <button onClick={()=>this.setState({visible: !this.state.visible})}>{this.state.visible ? 'Hide' : 'Show'} Leaderboard</button>
          <br/>
          <input value={this.state.maxSeconds} onChange={(e:any)=>this.setState({maxSeconds: e.currentTarget.value})}></input>
          <button onClick={()=>this.onStartTimer()}>Start Timer</button>
          <button onClick={()=>this.onPauseTimer()}>{this.state.paused ? 'Resume' : 'Pause'} Timer</button>
          <br/>
          {/* <input value={this.state.player} onChange={(e:any)=>this.setState({player: e.currentTarget.value})} />
          <input value={this.state.points} onChange={(e:any)=>this.setState({points: e.currentTarget.value})} />
          <button onClick={()=>this.onAdjustPoints()}>Adjust Points</button>
          <br/> */}
        </div>
        {!this.state.visible &&
          <div style={{width: '600px', height: '650px', backgroundColor: 'green', position: 'absolute', top: '0px', left: '0px'}}></div>
        }
        {this.state.resetLeaderboard && this.renderResetLeaderboardDialog()}
      </div>
    )
  }
}

export default StreamerPage;
