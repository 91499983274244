import React from 'react';
import { ApolloClient, InMemoryCache, createHttpLink, gql } from '@apollo/client';
import { Database } from './services/Database';
import { Account } from './services/Account';
import { Navigate } from 'react-router-dom';

const client = new ApolloClient({
  link: createHttpLink({
    uri: 'https://071262-08.myshopify.com/api/2023-01/graphql.json',
    headers: {
      'X-Shopify-Storefront-Access-Token': '2bd59775445dff00cc90f68a76fc1fa1'
    }
  }),
  cache: new InMemoryCache()
});

interface Product {
  id: string;
  title: string;
  description: string;
  productType: string;
  tags: string[];
  images: {
    edges: Array<{
      node: {
        src: string;
      }
    }>
  };
  variants: {
    edges: Array<{
      node: {
        id: string;
        price: {
          amount: string;
          currencyCode: string;
        }
      }
    }>
  };
}

interface StorePageState {
  loading: boolean;
  discountCode: string;
  products: Product[];
  allTags: string[];
  selectedTags: string[];
}

interface QueryResult {
  collections: {
    edges: Array<{
      node: {
        products: {
          edges: Array<{
            node: Product
          }>
        }
      }
    }>
  }
}

class StorePage extends React.Component<{}, StorePageState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      loading: false,
      discountCode: '',
      products: [],
      allTags: [],
      selectedTags: []
    }
  }

  componentDidMount(): void {
    this.fetchProducts();
  }

  async fetchProducts() {
    this.setState({ loading: true });
    try {
      const result = await client.query<{
        collections: any; data: QueryResult 
}>({
        query: gql`
          {
            collections(first: 1, query: "title:Nova Rally Site Store") {
              edges {
                node {
                  products(first: 250) {
                    edges {
                      node {
                        id
                        title
                        description
                        productType
                        tags
                        images(first: 1) {
                          edges {
                            node {
                              src
                            }
                          }
                        }
                        variants(first: 1) {
                          edges {
                            node {
                              id
                              price {
                                amount
                                currencyCode
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `
      });

      const collections = result.data?.collections?.edges || [];
      const products = collections[0]?.node?.products?.edges?.map((edge: { node: Product }) => edge.node) || [];
      const allTags = Array.from(new Set(products.flatMap((product: Product) => product.tags || [])));
      this.setState({ products, allTags: allTags as string[] });
    } catch (error) {
      console.error('Error fetching products:', error);
    }
    this.setState({ loading: false });
  }

  handleDiscountCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ discountCode: event.target.value });
  }

  handleTagToggle = (tag: string) => {
    this.setState(prevState => ({
      selectedTags: prevState.selectedTags.includes(tag) ? [] : [tag]
    }));
  }

  async onPurchase(variantId: string) {
    this.setState({loading: true});
    
    try {
      const result = await client.mutate({
        mutation: gql`
          mutation createCheckout($input: CheckoutCreateInput!) {
            checkoutCreate(input: $input) {
              checkout {
                id
                webUrl
              }
              checkoutUserErrors {
                code
                field
                message
              }
            }
          }
        `,
        variables: {
          input: {
            lineItems: [{ variantId, quantity: 1 }],
            customAttributes: [
              {
                key: "PlayFabId",
                value: Account.getUserId()
              }
            ]
          }
        }
      });

      const checkout = result.data.checkoutCreate.checkout;

      if (this.state.discountCode) {
        const discountResult = await client.mutate({
          mutation: gql`
            mutation checkoutDiscountCodeApplyV2($checkoutId: ID!, $discountCode: String!) {
              checkoutDiscountCodeApplyV2(checkoutId: $checkoutId, discountCode: $discountCode) {
                checkout {
                  id
                  webUrl
                }
                checkoutUserErrors {
                  code
                  field
                  message
                }
              }
            }
          `,
          variables: {
            checkoutId: checkout.id,
            discountCode: this.state.discountCode
          }
        });

        if (discountResult.data.checkoutDiscountCodeApplyV2.checkoutUserErrors.length > 0) {
          console.error('Error applying discount:', discountResult.data.checkoutDiscountCodeApplyV2.checkoutUserErrors);
        }
      }

      window.location.href = checkout.webUrl;
    } catch (error) {
      console.error('Error creating checkout:', error);
    }

    this.setState({loading: false});
  }

  renderProduct(product: Product) {
    const variant = product.variants.edges[0].node;
    const image = product.images.edges[0]?.node.src;
    return (
      <div key={product.id} className="flex-column" style={{alignItems: 'center', margin: '20px', width: '250px'}}>
        {image && (
          <img src={image} style={{width: '200px', height: '200px', objectFit: 'cover', borderRadius: '10px'}} alt={product.title} />
        )}
        <div style={{fontWeight: 'bold', marginTop: '10px'}}>{product.title}</div>
        <div>{this.formatPrice(variant.price)}</div>
        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', margin: '10px 0'}}>
          {product.tags.map(tag => (
            <span key={tag} style={{
              background: '#3856B0',
              color: 'white',
              borderRadius: '15px',
              padding: '5px 10px',
              margin: '2px',
              fontSize: '0.8em'
            }}>
              {tag}
            </span>
          ))}
        </div>
        <button onClick={() => this.onPurchase(variant.id)} style={{
          background: 'linear-gradient(197.78deg, #EF8F49 -12.54%, #F1BD42 104.72%)',
          border: 'none',
          color: 'white',
          padding: '10px 20px',
          textAlign: 'center',
          textDecoration: 'none',
          display: 'inline-block',
          fontSize: '16px',
          margin: '4px 2px',
          cursor: 'pointer',
          borderRadius: '15px',
          minHeight: '40px',
          minWidth: '60px'
        }}>
          Buy Now
        </button>
      </div>
    )
  }

  formatPrice(price: { amount: string; currencyCode: string }): string {
    const amount = parseFloat(price.amount);
    return `$${amount.toFixed(2)} ${price.currencyCode}`;
  }

  renderLoading() {
    return (
      <div className="dialog-cover">
        <div className="dialog-panel-centered" style={{maxWidth: '200px'}}>
          Loading...
        </div>
      </div>
    )
  }

  render() {
    if(!Account.isDeveloperLoggedIn())
      return <Navigate to="/" replace />

    const filteredProducts = this.state.selectedTags.length > 0
    ? this.state.products.filter(product => 
        product.tags.includes(this.state.selectedTags[0])
      )
    : this.state.products;

    return (
      <div className="page">
        <div className="flex-column" style={{maxWidth: '1200px', margin: 'auto'}}>
          <div>
            <input
              type="text"
              placeholder="Enter discount code"
              value={this.state.discountCode}
              onChange={this.handleDiscountCodeChange}
              style={{margin: '20px 0', padding: '10px', width: '200px'}}
            />
          </div>
          <div style={{margin: '20px 0', display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
  {this.state.allTags.map(tag => (
    <button
      key={tag}
      onClick={() => this.handleTagToggle(tag)}
      style={{
        background: this.state.selectedTags.includes(tag) ? 'linear-gradient(197.78deg, #EF8F49 -12.54%, #F1BD42 104.72%)' : '#3856B0',
        color: 'white',
        border: 'none',
        borderRadius: '15px',
        padding: '5px 10px',
        margin: '5px',
        cursor: 'pointer',
        fontSize: '0.8em'
      }}
    >
      {tag}
    </button>
  ))}
</div>
          <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
            {filteredProducts.map(product => this.renderProduct(product))}
          </div>
        </div>
        {this.state.loading && this.renderLoading()}
      </div>
    )
  }
}

export default StorePage;