import React from 'react';
import { Database } from './services/Database';

interface Character {
  id: string;
  name: string;
  bio: string;
  driving: number;
  boosting: number;
  actions: number;
  morale: number;
  ramming: number;
  shielding: number;
}

interface Car {
  id: string;
  name: string;
  description: string;
  weight: number;
  health: number;
  speed: number;
  acceleration: number;
  handling: number;
}

interface CodexPageState {
  activeTab: 'characters' | 'cars';
}

interface CodexPageState {
  activeTab: 'characters' | 'cars';
  expandedBios: Set<string>;
}

class CodexPage extends React.Component<{}, CodexPageState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      activeTab: 'characters',
      expandedBios: new Set()
    };
  }


  
  renderTabSelector() {
    const tabStyle: React.CSSProperties = {
      padding: '10px 20px',
      backgroundColor: '#2a4086',
      color: '#fff',
      border: 'none',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: 'bold',
      transition: 'background-color 0.3s, color 0.3s',
      outline: 'none',
    };

    const activeTabStyle: React.CSSProperties = {
      ...tabStyle,
      backgroundColor: '#FFD700',
      color: '#000',
      boxShadow: '0 0 10px rgba(255, 215, 0, 0.5)',
    };

    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
      }}>
        {['characters', 'cars'].map((tab) => (
          <div
            key={tab}
            onClick={() => this.setState({ activeTab: tab as CodexPageState['activeTab'] })}
            style={{
              ...this.state.activeTab === tab ? activeTabStyle : tabStyle,
              borderRadius: tab === 'characters' ? '5px 0 0 5px' : '0 5px 5px 0',
            }}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </div>
        ))}
      </div>
    );
  }

  renderStatBar(key: number, label: string, value: number) {
    let pips = [];
    for(let i = 0; i < 5; i++) {
      let color = i < value ? '#FFD700' : '#ffffff10';
      pips.push(
        <div key={i} style={{height: '15px', width: '100%', borderRadius: '5px', backgroundColor: color}} />
      )
    }

    return (
      <div key={key} style={{display: 'grid', gridTemplateColumns: '80px 1fr 20px', alignItems: 'center', gap: '10px'}}>
        <div style={{fontSize: '1.1em', color: 'white', textAlign: 'left'}}>{label}</div>
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr', columnGap: '3px'}}>
          {pips}
        </div>
        <div style={{fontSize: '1.1em', color: 'white', textAlign: 'right'}}>{value}</div>
      </div>
    )    
  }

  renderCharacterCard(character: Character) {
    const isExpanded = this.state.expandedBios.has(character.id);
    const truncatedBio = character.bio.slice(0, 100) + (character.bio.length > 100 ? '...' : '');

    return (
      <div key={character.id} style={{
        width: 'calc(25% - 20px)',
        minWidth: '250px',
        border: '1px solid #ffffff40',
        borderRadius: '10px',
        backgroundColor: '#3856B0',
        margin: '10px',
        overflow: 'hidden',
        fontFamily: 'inherit',
      }}>
        <div style={{
          position: 'relative',
          paddingBottom: '100%',
          backgroundColor: '#2a4086',
        }}>
          <img 
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }} 
            src={`/images/drivers/${character.id}.png`} 
            alt={character.name} 
          />
          <div style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            padding: '10px',
            backgroundColor: 'rgba(0,0,0,0.7)',
            color: '#ffffff',
            fontSize: '1.4em',
            textAlign: 'center',
          }}>
            {character.name}
          </div>
        </div>
        <div style={{
          padding: '12px',
          fontSize: '1em',
          color: '#ffffffcc',
        }}>
          <div style={{ marginBottom: '10px' }}>
            {isExpanded ? character.bio : truncatedBio}
            {character.bio.length > 100 && (
              <button
                onClick={() => this.toggleBio(character.id)}
                style={{
                  background: 'none',
                  border: 'none',
                  color: '#FFD700',
                  cursor: 'pointer',
                  marginLeft: '5px',
                  fontSize: '0.9em',
                }}
              >
                {isExpanded ? 'Read Less' : 'Read More'}
              </button>
            )}
          </div>
          <div className="flex-column" style={{color: 'gray', fontSize: '0.9em', rowGap: '8px', marginTop: '10px'}}>
            {this.renderStatBar(0, 'Driving', character.driving)}
            {this.renderStatBar(1, 'Boosting', character.boosting)}
            {this.renderStatBar(2, 'Actions', character.actions)}
            {this.renderStatBar(3, 'Morale', character.morale)}
            {this.renderStatBar(4, 'Ramming', character.ramming)}
            {this.renderStatBar(5, 'Shielding', character.shielding)}
          </div>
        </div>
      </div>
    );
  }

  renderCarCard(car: Car) {
    return (
      <div key={car.id} style={{
        width: 'calc(25% - 20px)',
        minWidth: '250px',
        border: '1px solid #ffffff40',
        borderRadius: '10px',
        backgroundColor: '#3856B0',
        margin: '10px',
        overflow: 'hidden',
        fontFamily: 'inherit',
      }}>
        <div style={{
          position: 'relative',
          paddingBottom: '75%',
          backgroundColor: '#2a4086',
        }}>
          <img 
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }} 
            src={`/images/cars/${car.id}.png`} 
            alt={car.name} 
          />
          <div style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            padding: '10px',
            backgroundColor: 'rgba(0,0,0,0.7)',
            color: '#ffffff',
            fontSize: '1.4em',
            textAlign: 'center',
          }}>
            {car.name}
          </div>
        </div>
        <div style={{
          padding: '12px',
          fontSize: '1em',
          color: '#ffffffcc',
        }}>
          <div style={{ marginBottom: '10px' }}>{car.description}</div>
          <div className="flex-column" style={{color: 'gray', fontSize: '0.9em', rowGap: '8px', marginTop: '10px'}}>
            {this.renderStatBar(0, 'Weight', car.weight)}
            {this.renderStatBar(1, 'Health', car.health)}
            {this.renderStatBar(2, 'Speed', car.speed)}
            {this.renderStatBar(3, 'Accel', car.acceleration)}
            {this.renderStatBar(4, 'Handling', car.handling)}
          </div>
        </div>
      </div>
    );
  }

  toggleBio = (characterId: string) => {
    this.setState(prevState => {
      const newExpandedBios = new Set(prevState.expandedBios);
      if (newExpandedBios.has(characterId)) {
        newExpandedBios.delete(characterId);
      } else {
        newExpandedBios.add(characterId);
      }
      return { expandedBios: newExpandedBios };
    });
  }

  render() {
    return (
      <div style={{
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '20px',
        fontFamily: 'inherit',
        color: '#ffffff',
        backgroundColor: '#1a2a4a',
      }}>
        {this.renderTabSelector()}
        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}>
          {this.state.activeTab === 'characters' && Database.drivers.map((character: Character) => this.renderCharacterCard(character))}
          {this.state.activeTab === 'cars' && Database.cars.map((car: Car) => this.renderCarCard(car))}
        </div>
      </div>
    );
  }
}

export default CodexPage;