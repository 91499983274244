import React from 'react';
import { Navigate } from 'react-router-dom';
import { Account } from './services/Account';

interface TwitchPageProps {
  onUpdate: Function;
}

interface TwitchPageState {
  navigate: string;
}

class TwitchPage extends React.Component<TwitchPageProps, TwitchPageState> {
  constructor(props: TwitchPageProps) {
    super(props);
    this.state = {
      navigate: ''
    }
  }

  componentDidMount(): void {
    if(window.location.hash.indexOf('access_token=') != -1) {
      let accessToken = window.location.hash.split('access_token=')[1].split('&')[0];
      this.login(accessToken);
    }
  }

  async login(accessToken: string) {
    setTimeout(async () => {
      await Account.continueLoginWithTwitch(accessToken);
      this.props.onUpdate();
      
      // Retrieve the return URL from sessionStorage
      let returnUrl = sessionStorage.getItem('loginReturnUrl') || '/';
      
      // Clear the stored URL
      sessionStorage.removeItem('loginReturnUrl');
      
      this.setState({navigate: returnUrl});
    }, 1000);
  }
  
  render() {
    if(this.state.navigate != '')
      return <Navigate to={this.state.navigate} />
      
    return (
      <div className="page">
        <div className="dialog-cover">
          <div className="dialog-panel" style={{maxWidth: '250px', padding: '20px'}}>
            Logging in...
          </div>
        </div>
      </div>
    );
  }
}

export default TwitchPage;