// const clientId = 'l2t5c8u25xyekg9ploc01l0d4js281';
const clientId = 'rsyvag0f9wd5ue8jbyz8836aqyc9f9';

export class Twitch {
  protected static accessToken:string = '';
  protected static chatSocket:WebSocket;

  public static setAccessToken(accessToken:string) {
    Twitch.accessToken = accessToken;
  }

  public static clearAccessToken() {
    Twitch.accessToken = '';
  }

  public static getStreams(gameId: string) {
    return new Promise((resolve, reject) => {
      const url = `https://api.twitch.tv/helix/streams?game_id=${gameId}`;
      var request = new XMLHttpRequest();
      request.open('GET', url);
      request.setRequestHeader('Authorization', `Bearer ${Twitch.accessToken}`);
      request.setRequestHeader('Client-ID', clientId); 
      request.send();

      request.onload = () => {
        let body = JSON.parse(request.responseText);
        if (request.status == 200) {
          resolve({ success: true, body });
        } else {
          reject({ success: false, body });
        }
      };

      request.onerror = () => {
        reject({ success: false, message: "Network error" });
      };
    });
  }

  public static login() {
    let url = `https://id.twitch.tv/oauth2/authorize?response_type=token`;
    url += `&client_id=rsyvag0f9wd5ue8jbyz8836aqyc9f9`;
    url += `&redirect_uri=${window.location.origin}/twitch`;
    url += `&scope=`;
    window.location.href = url;
  }

  public static async getUser(): Promise<{success:boolean, body?:any, message?:string}> {
    return new Promise((resolve, reject) => {
      const url = `https://api.twitch.tv/helix/users`;
      var request = new XMLHttpRequest();
      request.open('GET', url);
      request.setRequestHeader('Authorization', `Bearer ${Twitch.accessToken}`);
      request.setRequestHeader('Client-ID', clientId); 

      console.log('> TWITCH /users');

      request.send();

      request.onload = () => {
        let body = JSON.parse(request.responseText);
        console.log('< TWITCH', body);
        
        if (request.status == 200) {
          resolve({ success: true, body });
        } else {
          resolve({ success: false, body });
        }
      };

      request.onerror = () => {
        reject({ success: false, message: "Network error" });
      };
    });
  }

  public static async getStream(users:string[]): Promise<{success:boolean, body?:any, message?:string}> {
    let params = '';
    for(let i = 0; i < users.length; i++) {
      params += `user_login=${users[i]}`;
      if(i < users.length - 1)
        params += '&';
    }

    return new Promise((resolve, reject) => {
      const url = `https://api.twitch.tv/helix/streams?${params}`;
      var request = new XMLHttpRequest();
      request.open('GET', url);
      request.setRequestHeader('Authorization', `Bearer ${Twitch.accessToken}`);
      request.setRequestHeader('Client-ID', clientId); 

      console.log('> TWITCH /streams');

      request.send();

      request.onload = () => {
        let body = JSON.parse(request.responseText);
        console.log('< TWITCH', body);
        
        if (request.status == 200) {
          resolve({ success: true, body });
        } else {
          resolve({ success: false, body });
        }
      };

      request.onerror = () => {
        reject({ success: false, message: "Network error" });
      };
    });
  }
}