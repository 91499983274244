import React from 'react';
import { Database } from './services/Database';

interface CarPageState {
  id: string;
}

class CarPage extends React.Component<{}, CarPageState> {
  constructor(props:{}) {
    super(props);
    this.state = {
      id: ''
    }
  }

  componentDidMount(): void {
    let parts = window.location.pathname.split('/');
    if(parts.length == 3) 
      this.setState({id: parts[2]})
  }

  render() {
    let car = Database.getCar(this.state.id);

    let image = null;
    if(car)
      image = <img style={{width: '250px'}} src={'/images/cars/' + car.id + '.png'} />

    return (
      <div className="page">
        <div className="flex-row">
          {image}
        </div>
      </div>
    )
  }
}

export default CarPage;
