export class Amazon {
  public static post(method:string, command:string, params:any = null): Promise<{status:number, body?:any}> {
    return new Promise((resolve, reject)=>{
      var request = new XMLHttpRequest();
      // request.open('POST', `https://6js2lrsyg7.execute-api.us-west-2.amazonaws.com/prod/v1/${method}`);
      request.open('POST', `https://a6a8nr0rte.execute-api.eu-north-1.amazonaws.com/Prod/v1/${method}`);
      request.setRequestHeader('Content-Type', 'application/json');

      // request.setRequestHeader('Authorization', authorizationToken);
  
      let payload = {command};

      if(params) 
        payload = {command, ...params};

      let body = JSON.stringify(payload);

      console.log('> AWS /' + method, payload);
      
      request.send(body);
  
      request.onload = () => {
        let body = null;
        if (request.responseText != '')
          body = JSON.parse(request.responseText);
  
        console.log('< AWS', body);
  
        resolve({
          status: request.status,
          body: body
        });
      };
  
      request.onerror = (e) => {
        console.log('<== ERROR');
        resolve({
          status: 500,
          body: {message: 'Internal service error!'}
        });
      };
    });
  }
}