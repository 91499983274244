// const SOCKET_URL = 'wss://i612nrbhp4.execute-api.us-west-2.amazonaws.com/dev/';
const SOCKET_URL = 'wss://8kh9tm8lfc.execute-api.eu-north-1.amazonaws.com/production/';

export class Network {
  protected static socket: WebSocket;
  protected static connected: boolean = false;
  protected static listeners: any[] = [];
  protected static pinging: boolean = false;
  protected static pingTimer: any = null;
  protected static lastPingTime: number = 0;
  protected static channel: string = '';
  protected static username: string = '';

  public static addEventListener(callback:Function) {
    Network.listeners.push(callback);
  }

  public static removeEventListener(callback:Function) {
    for(let i = 0; i < Network.listeners.length; i++) {
      if(Network.listeners[i].callback == callback) {
        Network.listeners.splice(i, 1);
        return;
      }
    }
  }

  public static async connect() {
    if(Network.connected)
      return;

    return new Promise((resolve, reject) => {
      Network.socket = new WebSocket(SOCKET_URL);

      Network.socket.onopen = (event)=>{
        console.log('# SOCKET CONNECTED');
        Network.connected = true;
        Network.startPinging();
        resolve({success: true});
      };

      Network.socket.onerror = (event)=> {
        console.warn('# SOCKET ERROR');
        console.log(event);
        Network.connected = false;
        resolve({success: false});
      };

      Network.socket.onclose = (event)=>{
        console.log('# SOCKET DISCONNECTED');
        Network.connected = false;
      };

      Network.socket.onmessage = (message:any)=>Network.onMessage(message);
    });
  }

  public static registerUser(channel: string, username: string) {
    Network.channel = channel;
    Network.username = username;

    if (Network.connected) {
      Network.sendEvent({
        id: 'register-user',
        channel: channel,
        username: username
      });
    } else {
      console.error('Cannot register user: not connected to the network');
    }
  }

  public static async disconnect() {
    if (this.connected) {
      await this.unregisterUser();
      this.socket.close();
      this.connected = false;
      this.channel = '';
      this.username = '';
    }
  }

  public static async unregisterUser() {
    if (this.connected && this.channel && this.username) {
      await this.sendEvent({
        id: 'unregister-user',
        channel: this.channel,
        username: this.username
      });
      console.log(`Unregistered user ${this.username} from channel ${this.channel}`);
    } else {
      console.log('Unable to unregister user: not connected or missing channel/username');
    }
  }

  public static isConnected(): boolean {
    return Network.connected;
  }

  public static onMessage(message: any) {
    let data = JSON.parse(message.data);
    console.log('Received network event:', data);
    
    if(data.id === 'host-status-check-complete') {
        console.log('Creating host status update event');
        const event = new CustomEvent('host-status-update', {
            detail: {
                host: data.host,
                isOnline: data.isOnline
            }
        });
        console.log('Dispatching event:', event);
        window.dispatchEvent(event);
        console.log('Event dispatched');
    }
  }

  public static async sendEvent(event:any) {
    if(!Network.socket)
      return;

    let body = JSON.stringify(event);

    let logEvent = JSON.parse(JSON.stringify(event));
    delete logEvent.id;
    let keys = Object.keys(logEvent);
    if(keys.length == 0)
      logEvent = null;

    if(logEvent)
      console.log('> SOCKET', event.id, logEvent);
    else
      console.log('> SOCKET', event.id);

    try {
      Network.socket.send(body);
    }
    catch(e) {
      console.error('Failed to send command!');
      console.log(e);
    }
  }

  public static async sendEventWithResponse(event: any): Promise<any> {
    if (!Network.socket) {
      throw new Error('Socket not connected');
    }

    return new Promise((resolve, reject) => {
      this.sendEvent(event);
      
      // Set up a one-time listener for the response
      const responseListener = (message: any) => {
        const data = JSON.parse(message.data);
        if (data.id === `${event.id}-response`) {
          Network.socket.removeEventListener('message', responseListener);
          resolve(data);
        }
      };
      
      Network.socket.addEventListener('message', responseListener);
      
      // Set a timeout for the response
      setTimeout(() => {
        Network.socket.removeEventListener('message', responseListener);
        reject(new Error('Response timeout'));
      }, 5000); // 5 second timeout
    });
  }

  protected static startPinging() {
    if(Network.pinging) return;
    Network.pinging = true;

    Network.pingTimer = setInterval(() => {
      let now = performance.now();
      if(Network.lastPingTime == 0)
        Network.lastPingTime = now;
      let elapsed = now - Network.lastPingTime;

      // console.log(elapsed, document.hasFocus());
  
      if(document.hasFocus()) {
        if(Network.isConnected()) {
          if(elapsed > 60000) {
            Network.sendEvent({id: 'keepalive'})
            Network.lastPingTime = now;
          }
        }
        else {
          console.log('# SOCKET RECONNECTING...');
          Network.connect();
        }
      }
    }, 1000);
  }

  protected static stopPinging() {
    clearInterval(Network.pingTimer);
    Network.pinging = false;
  }

  public static disconnectSync() {
    if (this.connected) {
      // Send a synchronous request to unregister the user
      const xhr = new XMLHttpRequest();
      xhr.open('POST', SOCKET_URL, false);  // false makes the request synchronous
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify({
        id: 'unregister-user',
        channel: this.channel,
        username: this.username
      }));

      this.socket.close();
      this.connected = false;
      this.channel = '';
      this.username = '';
    }
  }

  public static async ensureConnection(): Promise<void> {
    if (!this.isConnected()) {
      await this.connect();
    }
  }

  public static async checkHostStatus(host: string): Promise<boolean> {
    try {
      await this.ensureConnection();
      const response = await this.sendEventWithResponse({
        id: 'check-host-status',
        host: host
      });
      console.log('Check host status response:', response);
      if (response && typeof response.isOnline === 'boolean') {
        return response.isOnline;
      } else {
        console.error('Unexpected response format:', response);
        return false;
      }
    } catch (error) {
      console.error('Error checking host status:', error);
      return false;
    }
  }

  public static async send(message: any): Promise<void> {
    await Network.ensureConnection();
    Network.socket.send(JSON.stringify(message));
  }
}

