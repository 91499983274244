import React from 'react';
import { Database } from './services/Database';
import { Navigate, NavLink } from 'react-router-dom';
import { Account } from './services/Account';

interface InventoryPageState {
  inventory: any[];
}

class InventoryPage extends React.Component<{}, InventoryPageState> {
  constructor(props:{}) {
    super(props);
    this.state = {
      inventory: []
    }
  }

  componentDidMount(): void {
    Account.addCallback('InventoryPage', ()=>this.load());
    this.load();
  }

  componentWillUnmount(): void {
    Account.removeCallback('InventoryPage');
  }

  async load() {
    if(Account.isLoggedIn()) {
      let inventory = await Account.getInventory();
      this.setState({inventory});
    }
    else
      this.setState({inventory: []})
  }

  render() {
    if(!Account.isDeveloperLoggedIn())
      return <Navigate to="/" replace />

    let inventory = [];
    for(let i = 0; i < this.state.inventory.length; i++) {
      let asset = Database.getAsset(this.state.inventory[i].id);
      let amount = this.state.inventory[i].amount;
      inventory.push({asset, amount});
    }

    inventory.sort((a, b)=>{
      if(a.asset.collection > b.asset.collection)
        return 1;
      else if(a.asset.collection > b.asset.collection)
        return -1;

      if(a.asset.name > b.asset.name)
        return 1;
      else if(a.asset.name > b.asset.name)
        return -1;

      return 0;
    })

    let divs = [];
    for(let i = 0; i < inventory.length; i++) {
      let asset = inventory[i].asset;
      let amount = inventory[i].amount;
      let collection = Database.getCollection(asset.collection);
      let src = `/images/assets/${asset.collection}/${asset.image ? asset.image : asset.id}.png`;
      divs.push(
        <div key={i} style={{display: 'grid', gridTemplateColumns: '60px 1fr 50px', columnGap: '15px', alignItems: 'center'}}>
          <img src={src} style={{width: '100%', borderRadius: '3px'}} />
          <div>{asset.name}<div className="subtext">{collection.asset}</div></div>
          <div style={{textAlign: 'right', marginRight: '10px'}}>{amount}</div>
        </div>
      )
    }

    return (
      <div className="page">
        <div className="panel" style={{maxWidth: '400px', margin: 'auto'}}>
          <div className="flex-column">
            {divs}
          </div>
        </div>
      </div>
    )
  }
}

export default InventoryPage;
