import { Amazon } from "./Amazon";

export class Races {
  public static async getSeasonLeaderboard(season:string) {
    let response = await Amazon.post('races', 'get-season-leaderboard', {season});
    return {success: (response.status == 200), body: response.body};
  }

  public static async getHostLeaderboard(host:string) {
    let response = await Amazon.post('races', 'get-host-leaderboard', {host});
    return {success: (response.status == 200), body: response.body};
  }

  public static async resetHostLeaderboard(host:string) {
    let response = await Amazon.post('races', 'reset-host-leaderboard', {host});
    return {success: (response.status == 200), body: response.body};
  }

  public static async getRace(id:string) {
    let response = await Amazon.post('races', 'get-race', {id});
    return {success: (response.status == 200), body: response.body};
  }

  public static async getLastRace(host:string) {
    let response = await Amazon.post('races', 'get-races', {host, limit: 1});
    return {success: (response.status == 200), body: response.body};
  }

  public static async getRaces(params?: {host?:string, user?:string}) {
    let response = await Amazon.post('races', 'get-races', params);
    return {success: (response.status == 200), body: response.body};
  }

  public static async getRaceStats(id:string) {
    let response = await Amazon.post('races', 'get-race-stats', {id});
    return {success: (response.status == 200), body: response.body};
  }

  public static async getRaceEvents(id:string) {
    let response = await Amazon.post('races', 'get-race-events', {id});
    return {success: (response.status == 200), body: response.body};
  }

  public static async getLeagues(host:string) {
    let response = await Amazon.post('races', 'get-leagues', {host});
    return {success: (response.status == 200), body: response.body};
  }
}